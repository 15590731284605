/* eslint-disable */
import React from 'react';
import '../shared.scss';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import TokenService from '../../services/Authentication/token.service';
import DroneService from '../../services/Drones/drone.service';
import UserService from "../../services/Authentication/user.service";

export default function Users() {
  const [users, setUsers] = React.useState([]);
  const [drones, setDrones] = React.useState([]);

  const fetchUsers = async () => {
    try {
      const userData = await UserService.getUsers();
      setUsers(userData);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchDrones = async () => {
    try {
      const droneData = await DroneService.getDrones();
      setDrones(droneData);
    } catch (error) {
      console.error("Error fetching drones:", error);
    }
  };

  const handleDeleteDrone = React.useCallback((id) => {
    DroneService.deleteDrone(id)
      .then(() => {
        window.toast.success('The drone was deleted successfully.');
      })
      .catch((reason) => window.toast.error(reason.title));
  }, []);

  React.useEffect(() => {
    fetchUsers();
    fetchDrones();
  }, []);

  return (
    <PageWrapper fullscreen>
      <div style={{ padding: '50px' }} className="Users">
        <div style={{ justifyContent: 'space-between', display: 'flex' }}>
          <h2>Users</h2>
          <Link to="/users/new">
            <button
              type="submit"
              className="bg-blue rounded-lg px-2 py-1 text-white border-2 border-blue hover:bg-white hover:text-blue transition"
            >
              Add user +
            </button>
          </Link>
        </div>

        <table style={{ width: '100%' }}>
          <thead>
          <tr>
            <td>E-mail</td>
            <td>Name</td>
            <td>Role</td>
            <td>Active</td>
            <td>Actions</td>
          </tr>
          </thead>
          <tbody>
          {users?.map((user, index) => (
            <tr key={user.id}>
              <td>{user.email}</td>
              <td>{user.name}</td>
              <td>{TokenService.getRole(user?.role)}</td>
              <td>{user.isActive ? 'Yes' : 'No'}</td>
              <td>
                <Link to={`/users/${user.userId}`}>
                  <FontAwesomeIcon icon={solid('pen')} className="select-none text-blue cursor-pointer mt-1" />
                </Link>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
        <br></br>

        <div style={{ justifyContent: 'space-between', display: 'flex' }}>
          <h2>Drones</h2>
          <Link to="/drones/new">
            <button className="bg-blue rounded-lg px-2 py-1 text-white border-2 border-blue hover:bg-white hover:text-blue transition">
              Add drone +
            </button>
          </Link>
        </div>

        <table style={{ width: '100%' }}>
          <thead>
          <tr>
            <td>Drone ID</td>
            <td>Name</td>
            <td>User Name</td>
            <td>User Email</td>
            <td>Actions</td>
          </tr>
          </thead>
          <tbody>
          {drones?.map((drone, index) => (
            <tr key={index}>
              <td>{drone.droneId}</td>
              <td>{drone.name}</td>
              <td>{drone.user.name}</td>
              <td>{drone.user.email}</td>
              <td>
                <Link to={`/drones/${drone.droneId}`}>
                  <FontAwesomeIcon icon={solid('pen')} className="select-none text-blue cursor-pointer mt-1" />
                </Link>

                <button style={{ marginLeft: 5 }} onClick={() => handleDeleteDrone(drone.droneId)}>
                  <FontAwesomeIcon icon={solid('trash-can')} className="select-none text-blue cursor-pointer mt-1" />
                </button>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    </PageWrapper>
  );
}
