function degreesToRadians(degrees) {
  return degrees * (Math.PI / 180);
}

export function calculateDistanceBetweenPoints(pointA, pointB) {
  const EARTH_RADIUS = 6371;
  const lat1Rad = degreesToRadians(pointA.latitude);
  const lat2Rad = degreesToRadians(pointB.latitude);
  const lon1Rad = degreesToRadians(pointA.longitude);
  const lon2Rad = degreesToRadians(pointB.longitude);

  const dLat = lat2Rad - lat1Rad;
  const dLon = lon2Rad - lon1Rad;

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return EARTH_RADIUS * c;
}

export function calculateTotalDistance(waypoints) {
  let totalDistance = 0;
  for (let i = 1; i < waypoints.length; i++) {
    const distanceBetweenWaypoints = calculateDistanceBetweenPoints(waypoints[i - 1], waypoints[i]);
    totalDistance += distanceBetweenWaypoints;
  }

  return totalDistance;
}
