import React from 'react';
import './ReportLog.scss';

export default function ReportLog({ validationErrors, showEditMissionButton, displayMinimizedReportLog }) {
  return (
    <div className="report-log-container">
      <div className="report-log-header">We found some safety problems with your mission.</div>
      <div className="report-log-sub-header">Please, solve the problems below to save the mission:</div>
      <div className="report-log-error-list-container">
        <div className="report-log-content">
          {validationErrors.map((message) => (
            <div className={`report-log-${message.type}-item border`}>
              <div className="error-header">{message.header}</div>
            </div>
          ))}
        </div>
      </div>
      {showEditMissionButton && (
        <button
          type="button"
          className="bg-blue mt-4 mb-1 h-10 rounded-md w-full text-white"
          onClick={displayMinimizedReportLog}
        >
          Edit Mission
        </button>
      )}
    </div>
  );
}
