const ROLES = {
  0: 'Admin',
  1: 'User',
};

const getLocalAccessToken = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  return user?.token;
};

const updateLocalAccessToken = (token) => {
  const user = JSON.parse(localStorage.getItem('user'));
  user.token = token;
  localStorage.setItem('user', JSON.stringify(user));
};

const removeLocalAccessToken = () => {
  localStorage.removeItem('user');
};

const getRole = (role) => ROLES[role];

const getRoleFromToken = () => {
  const token = getLocalAccessToken();
  if (token) {
    const payload = JSON.parse(atob(token.split('.')[1]));
    return payload['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'][0];
  }

  return null;
};

const getUser = () => JSON.parse(localStorage.getItem('user'));

const setUser = (user) => {
  localStorage.setItem('user', JSON.stringify(user));
};

const removeUser = () => {
  localStorage.removeItem('user');
};

const validateTokenLifetime = () => {
  const token = getLocalAccessToken();
  if (token) {
    const decode = JSON.parse(atob(token.split('.')[1]));
    return decode.exp * 1000 >= new Date().getTime();
  }

  return false;
};

const TokenService = {
  validateTokenLifetime,
  getLocalAccessToken,
  getRole,
  getRoleFromToken,
  updateLocalAccessToken,
  removeLocalAccessToken,
  getUser,
  setUser,
  removeUser,
};

export default TokenService;
