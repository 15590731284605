import React from 'react';
import './appCards.scss';
import { Link } from 'react-router-dom';

export default function AppCard({ app }) {
  return (
    <Link to={`/appstore/${app.id}`} state={{ app }} className="app-card max-w-sm overflow-hidden shadow-lg rounded-xl">
      <div className="app-card max-w-sm overflow-hidden shadow-lg rounded-xl">
        <div className="flex items-center justify-center rounded-xl" style={{ maxHeight: '80%' }}>
          <img
            className="w-full rounded-xl"
            src={app.logoUrl}
            alt="Logo"
            style={{ minWidth: 'fit-content', maxHeight: '100%', maxWidth: '100%' }}
          />
        </div>
        <div className="px-5 py-4 mt-auto">
          <div className="text-xl">{app.name}</div>
        </div>
      </div>
    </Link>
  );
}
