import React, { useEffect, useRef } from 'react';
import flightCheck from '../../assets/images/pre-flight-check.png';

/**
 * This component renders the Manual Inspection element
 * which displays the required actions that need to be
 * performed by the user before launching the drone
 *
 */

export default function StepperCheckManual({ stepTitle, stepDescription }) {
  const textareaRef = useRef(null);

  useEffect(() => {
    const textarea = textareaRef.current;
    // Set the height to match the content
    textarea.style.height = `${textarea.scrollHeight}px`;
    // Disable resizing
    textarea.style.resize = 'none';
  }, []);

  return (
    <div className="w-full md:w-4/5 bg-lightGray px-4 md:px-16 pt-5 ml-0 md:ml-5">
      <h2>Manual Inspection</h2>
      <div className="flex flex-col h-550 mt-8">
        <img className="max-h-[25rem] w-full object-cover rounded-xl" alt="flightcheck" src={flightCheck} />
      </div>
      <div className="mt-6">
        <h3>{stepTitle}</h3>
        <textarea ref={textareaRef} className="w-full bg-lightGray mt-6" disabled defaultValue={stepDescription} />
      </div>
    </div>
  );
}
