/* eslint-disable */
import React, { useLayoutEffect, useState } from 'react';
import { Router } from 'react-router-dom';

export default function CustomRouter({ history, children }) {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router location={state.location} navigationType={state.action} navigator={history}>
      {children}
    </Router>
  );
}
