import api from '../api';

const getDrones = () =>
  api
    .get('/drones')
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response.data));

const updateDrone = (drone) =>
  api
    .put(`/drones/${drone.droneId}`, drone)
    .then((res) => res)
    .catch((err) => Promise.reject(err.response.data));

const createDrone = (drone) =>
  api
    .post(`/drones/`, drone)
    .then((res) => res)
    .catch((err) => Promise.reject(err.response.data));

const deleteDrone = (id) => {
  api
    .delete(`/drones/${id}/`)
    .then((res) => res)
    .catch((err) => Promise.reject(err.response.data));
};

const DroneService = {
  getDrones,
  updateDrone,
  createDrone,
  deleteDrone,
};

export default DroneService;
