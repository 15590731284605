import React from 'react';
import './Map.scss';
import mapboxgl from 'mapbox-gl';

import MapGL, {
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
  Source,
  Layer,
  Marker,
} from 'react-map-gl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import WaypointPopup from '../../pages/Missions/WaypointPopup/WaypointPopup';

import DroneSource from './DashboardMap/DroneSource';
import checkSameCoordinatesValidator from '../../helpers/checkSameCoordinatesValidator';

// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const initialViewState = {
  longitude: 4.55,
  latitude: 52.23,
  zoom: 6.5,
};

export default function MissionMap({
  clickHandler,
  mapRef,
  lineStrings,
  mapStyle,
  waypoints,
  editable,
  drones,
  selectedWaypoint,
  markerClickedHandler,
  markerDraggedHandler,
}) {
  const renderMarkerIcon = (wp) => {
    if (selectedWaypoint?.order === wp.order) {
      return (
        <FontAwesomeIcon
          className="fa-2x map-waypoint fa-rotate-by"
          style={{ '--fa-rotate-angle': `${wp.heading}deg` }}
          icon={solid('chevron-up')}
        />
      );
    }

    if (wp.order === 1) {
      return <FontAwesomeIcon className="fa-2x map-waypoint" icon={solid('home')} />;
    }

    if (
      waypoints.length > 1 &&
      wp.order === waypoints.length &&
      wp.longitude === waypoints[0].longitude &&
      wp.latitude === waypoints[0].latitude
    ) {
      return ' ';
    }

    return <FontAwesomeIcon className="fa-2x map-waypoint" icon={solid('map-marker-alt')} />;
  };

  const markers = () =>
    waypoints &&
    waypoints.length > 0 &&
    waypoints
      .filter((wp) => wp.waypointTypeDefinitionId === 0)
      .map(
        (wp) =>
          !checkSameCoordinatesValidator(wp, waypoints[0]) && (
            <Marker
              id={wp.order}
              key={`marker-${wp.order}`}
              draggable={editable}
              longitude={wp.longitude}
              latitude={wp.latitude}
              anchor="bottom"
              onClick={(e) => markerClickedHandler(wp, e)}
              onDrag={(e) => markerDraggedHandler(wp.order, e)}
            >
              {renderMarkerIcon(wp)}
            </Marker>
          )
      );

  const photoArea = () => {
    const filteredWaypoints = waypoints.filter((waypoint) => waypoint.waypointTypeDefinitionId === 1);

    if (filteredWaypoints.length > 0) {
      const coordinates = filteredWaypoints.map((waypoint) => [waypoint.longitude, waypoint.latitude]);
      coordinates.push([waypoints[0].longitude, waypoints[0].latitude]);

      const geoJson = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Polygon',
          coordinates: [coordinates],
        },
      };

      return (
        <Source id="polygon-source" type="geojson" data={geoJson}>
          <Layer
            id="polygon-layer"
            type="fill"
            beforeId="drone"
            paint={{
              'fill-color': '#0000FF',
              'fill-opacity': 0.1,
            }}
          />
        </Source>
      );
    }

    return null;
  };

  return (
    <>
      <MapGL
        initialViewState={initialViewState}
        ref={mapRef}
        mapStyle={`mapbox://styles/mapbox/${mapStyle}`}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
        onClick={clickHandler}
      >
        <GeolocateControl position="bottom-right" />

        <FullscreenControl position="bottom-right" />

        <NavigationControl position="bottom-right" />

        <ScaleControl />

        {photoArea()}

        {markers()}

        {waypoints &&
          waypoints
            .filter((wp) => wp.order === 1 && wp.waypointTypeDefinitionId === 0)
            .map((wp) => (
              <Source
                key={`circle-source-${wp.order}`}
                id={`circle-source-${wp.order}`}
                type="geojson"
                data={{
                  type: 'FeatureCollection',
                  features: [
                    {
                      type: 'Feature',
                      properties: {},
                      geometry: {
                        type: 'Point',
                        coordinates: [wp.longitude, wp.latitude],
                      },
                    },
                  ],
                }}
              >
                <Layer
                  key={`circle-layer-${wp.order}`}
                  id={`circle-layer-${wp.order}`}
                  type="circle"
                  paint={{
                    'circle-radius': ['interpolate', ['exponential', 5], ['zoom'], 0, 0, 20, 300],
                    'circle-color': 'lightblue',
                    'circle-opacity': 0.6,
                  }}
                />
              </Source>
            ))}

        {selectedWaypoint && <WaypointPopup />}

        {lineStrings != null && (
          <Source id="my-data" type="geojson" data={lineStrings}>
            <Layer
              id="drone"
              type="line"
              paint={{
                'line-color': '#acacac',
                'line-width': 3,
              }}
            />
          </Source>
        )}

        {drones != null &&
          drones.map((drone) => (
            <DroneSource key={drone.droneId} drone={drone} selectedDrone={drone} onDroneFocusing={null} />
          ))}
      </MapGL>
      <div className="overlay" />
    </>
  );
}
