import React from 'react';
import './Select.scss';

export default function Select({ label, placeholder, className, onChangeSelect, options = [] }) {
  return (
    <div className={className}>
      {label && <p className="text-grey mt-4 mb-1">{label}</p>}
      <select defaultValue="" onChange={onChangeSelect}>
        <option disabled value="">
          {placeholder}
        </option>

        {options.map((option) => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}
