// eslint-disable-next-line import/prefer-default-export
export class FlightReport {
  constructor(json = {}) {
    this.flightReportId = json.flightReportId;
    this.missionId = json.missionId ?? null;
    this.droneId = json.droneId ?? null;
    this.executeAt = json.executeAt ?? null;
    this.executeImmediate = json.executeImmediate ?? false;
  }
}
