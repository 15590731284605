import api from '../api';
import { Mission } from '../../classes/Mission';
import { Waypoint } from '../../classes/Waypoint';

const getMissions = () =>
  api
    .get('/missions')
    .then((response) =>
      window._.reverse(response.data).map((jsonMission) => {
        const waypoints = window._.orderBy(jsonMission.waypoints, 'order', 'asc').map(
          (waypoint, index) =>
            new Waypoint({
              ...waypoint,
              order: index + 1,
            })
        );

        return new Mission({
          ...jsonMission,
          waypoints,
        });
      })
    )
    .catch((err) => Promise.reject(err.response.data));

const getMissionWaypoints = (missionId) =>
  api
    .get(`/missions/${missionId}/waypoints`, missionId)
    .then((res) => res)
    .catch((err) => Promise.reject(err.response.data));

const getMission = (missionId) =>
  api
    .get(`/missions`, missionId)
    .then((res) => res)
    .catch((err) => Promise.reject(err.response.data));

const createMission = (mission) =>
  api
    .post(`/missions`, mission)
    .then((res) => res)
    .catch((err) => Promise.reject(err.response.data));

const updateMission = (mission) =>
  api
    .put(`/missions/${mission.missionId}`, mission)
    .then((res) => res)
    .catch((err) => Promise.reject(err.response.data));

const deleteMission = (missionId) =>
  api
    .delete(`/missions/${missionId}`)
    .then((res) => res)
    .catch((err) => Promise.reject(err.response.data));

const MissionService = {
  getMissions,
  getMissionWaypoints,
  getMission,
  createMission,
  updateMission,
  deleteMission,
};

export default MissionService;
