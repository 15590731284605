import React, { useContext } from 'react';
import { MissionsContext } from '../../../pages/Missions/Missions';
import './MissionAppCard.scss';

export default function MissionAppCard({ app }) {
  const { setSelectedApps, selectedApps } = useContext(MissionsContext);

  const handleClick = () => {
    if (selectedApps.find((selected) => selected.id === app.id)) {
      setSelectedApps(selectedApps.filter((selected) => selected.id !== app.id));
    } else {
      setSelectedApps(selectedApps.concat(app));
    }
  };

  const isSelected = selectedApps.some((selectedApp) => selectedApp.id === app.id);

  return (
    <button
      type="button"
      key={app.id}
      onClick={() => handleClick()}
      className={`app-store-card ${isSelected ? 'selected' : ''} mt-5`}
    >
      <div className="app-store-card-text">
        <img src={app.logoUrl} alt={app.name} className="app-logo" />
        <h4 className="ml-4 app-title text-xl">{app.name}</h4>
      </div>
      <p className="app-description">{app.description}</p>
    </button>
  );
}
