import React, { useContext } from 'react';
import './Weather.scss';
import axios from 'axios';
import WeatherIcon from './WeatherIcon';
import Tooltip from '../../../Tooltip/Tooltip';
import { SideNavContext } from '../SideNav';

/**
 *
 * This is the Weather API (https://openweathermap.org/api) that is used to display the current weather in the bottom
 * of the SideNav and is also used to perform External checks for the pre-launch of a drone mission
 *
 */

export async function getWeatherInfo(coords) {
  try {
    const response = await axios.get(
      `https://api.openweathermap.org/data/2.5/weather?lat=${coords.latitude}&lon=${coords.longitude}&units=metric&lang=en&appid=${process.env.REACT_APP_OPENWEATHER_API_KEY}`
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export default function Weather() {
  const { weatherTooltipOpen, setWeatherTooltipOpen, weatherInfo } = useContext(SideNavContext);

  return (
    <div className="Weather select-none">
      <WeatherIcon icon={weatherInfo?.weather && weatherInfo?.weather[0]?.icon} clickEnabled />
      <Tooltip
        open={weatherTooltipOpen}
        onClose={() => {
          setWeatherTooltipOpen(false);
        }}
        position="bottom"
      >
        <WeatherTooltip />
      </Tooltip>
    </div>
  );
}

function WeatherTooltip() {
  const { weatherInfo } = useContext(SideNavContext);

  return (
    <div className="Weather__tooltip">
      <h3>Weather</h3>
      <div className="Weather__tooltip__content">
        <WeatherIcon icon={weatherInfo.weather[0].icon} />
        <h1>
          {Math.round(weatherInfo.main.temp * 10) / 10}&nbsp;<em>&deg;C</em>
        </h1>
      </div>
      <p>
        Wind speed: <em>{Math.round(weatherInfo.wind.speed * 3.6 * 10) / 10} km/h</em>
      </p>
    </div>
  );
}
