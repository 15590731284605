import api from '../api';

const getFlightReportAppsByFlightReportId = (flightReportId) =>
  api
    .get(`/flightreport-apps/${flightReportId}/all`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response.data));

const getFlightReportApp = (flightReportAppId) =>
  api
    .put(`/flightreport-apps/${flightReportAppId}`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response.data));

const createFlightReportApp = (flightReportApp) =>
  api
    .post(`/flightreport-apps`, flightReportApp)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response.data));

const manualStartProcessing = (flightReportAppId) =>
  api
    .get(`flightreport-apps/manual-start/${flightReportAppId}`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response.data));

const FlightReportAppService = {
  getFlightReportAppsByFlightReportId,
  getFlightReportApp,
  createFlightReportApp,
  manualStartProcessing,
};

export default FlightReportAppService;
