/* eslint-disable */

import React, { useEffect, useState } from 'react';
import '../shared.scss';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import api from '../../services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import Input from '../../components/Input/Input';
import Select from '../../components/Select/Select';
import UserService from '../../services/Authentication/user.service';
import TokenService from '../../services/Authentication/token.service';

export default function UserForm() {
  const navigate = useNavigate();

  const { id } = useParams();
  const [user, setUser] = React.useState(null);

  const [roleOptions, setRoleOptions] = useState([
    { label: 'Admin', value: 0 },
    { label: 'User', value: 1 },
  ]);

  const nameRef = React.useRef(null);
  const emailRef = React.useRef(null);
  const roleRef = React.useRef(null);
  const activeRef = React.useRef(null);

  const isNewUser = id === 'new' ? true : false;

  const handleSubmit = (data) => {
    if (isNewUser) {
      createUser(data);
    } else {
      updateUser(data);
    }
  };

  const createUser = (user) => {
    UserService.saveUser(user)
      .then(({ data }) => {
        navigate('/users');
        window.toast.success('The user was created successfully.');
      })
      .catch((reason) => window.toast.error(reason.title));
  };

  const updateUser = (user) => {
    UserService.updateUser(user)
      .then(() => {
        window.toast.success('The user was updated successfully.');
      })
      .catch((reason) => window.toast.error(reason.title));
  };

  const fetchUser = (id) => {
    api.get(`/users/${id}/`).then(({ data }) => {
      setUser(data);
      nameRef.current.value = data.name;
      emailRef.current.value = data.email;
      activeRef.current.checked = data.isActive;

      roleRef.current.value = TokenService.getRoleFromToken();
    });
  };

  React.useEffect(() => {
    if (!isNewUser) {
      fetchUser(id);
    } else {
      activeRef.current.checked = true;
    }
  }, [id]);

  return (
    <PageWrapper fullscreen>
      <div style={{ padding: '50px' }} className="User-form">
        <h2>{isNewUser ? 'Create' : 'Update'} User</h2>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(user);
          }}
        >
          <Input
            label="Email address"
            handleChange={(data) => {
              setUser({
                ...user,
                email: data.target.value,
              });
            }}
            handleRef={emailRef}
            inputType="email"
          />

          <Input
            label="Name"
            handleChange={(data) => {
              setUser({
                ...user,
                name: data.target.value,
              });
            }}
            handleRef={nameRef}
            inputType={'text'}
          />

          <Select
            onChangeSelect={(data) => {
              setUser({
                ...user,
                role: parseInt(data.target.options[data.target.selectedIndex].value, 10),
              });
            }}
            className="mt-5"
            label="Select role"
            placeholder="Select role"
            options={roleOptions}
          />

          {isNewUser ? (
            <Input
              label="Password"
              handleChange={(data) => {
                setUser({
                  ...user,
                  password: data.target.value,
                });
              }}
              inputType={'text'}
            />
          ) : null}

          <div style={{ justifyContent: 'left', display: 'flex' }}>
            <Input
              style={{ width: '50% ' }}
              label="Active"
              handleChange={(data) => {
                setUser({
                  ...user,
                  isActive: activeRef.current.checked,
                });
              }}
              handleRef={activeRef}
              inputType={'checkbox'}
            />
          </div>

          <button
            type="submit"
            className="bg-blue rounded-lg px-2 py-1 text-white border-2 border-blue hover:bg-white hover:text-blue transition"
          >
            Save
          </button>
        </form>
      </div>
    </PageWrapper>
  );
}
