import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import HorizontalStepper from '../../components/Steppers/HorizonntalStepper';
import DroneAutomaticCheck from '../../components/DroneAutomaticCheck/DroneAutomaticCheck';
import Button from '../../components/Button/Button';
import useWeatherAPI from '../../hooks/useWeatheAPI';
import CenteredMapView from './CenteredMapView';
import useSignalRConnection from '../../hooks/useSignalRConnection';
import useDroneCheckService from '../../hooks/useDroneCheckService';
import GenericService from '../../services/generic.service';
import AlertAdminPopUp from './AlertAdminPopUp';
import { FlightReport } from '../../classes/FlightReport';
// import fakeTelData from '../../services/AutomaticChecks/fakeTelemetryData.json';

/**
 * This component renders the Automatic Checks element
 * which displays the checks done by the drone before starting
 * the mission.
 *
 */

function AutomaticChecks({ isAdmin }) {
  const droneId = localStorage.getItem('droneId');
  const missionId = localStorage.getItem('missionId');
  const { telemetryData } = useSignalRConnection(droneId);
  // Hardcoded drone id '6f35df8b-cd64-4509-ae0c-cfb28773eefd' droneName -> New Drone
  // const telemetryData = fakeTelData; // For testing

  const {
    isDroneConnected,
    isBatteryLevelEnough,
    isInternetConnectionLive,
    areChecksPending,
    areHealthChecksFailing,
    errors,
  } = useDroneCheckService(telemetryData);

  // Fetching API weather data
  const [isWeatherPending, setIsWeatherPending] = useState(true);
  const [isRainPending, setIsRainPending] = useState(true);
  const [isWindPending, setIsWindPending] = useState(true);
  const [isDayPending, setIsDayPending] = useState(true);
  const [isRainLow, setIsRainLow] = useState(null);
  const [isWindLight, setIsWindLight] = useState(null);
  const [isVisible, setIsVisible] = useState(null);

  // const [isLoadingWeather, setIsLoadingWeather] = useState(true);

  const { isLightRain, isLightWind, isDay, isPending, weatherErrors } = useWeatherAPI();
  const externalConditions = [
    ['Acceptable Rain Level', isRainLow, isRainPending],
    ['Acceptable Wind Speed', isWindLight, isWindPending],
    ['Enough Visibility', isVisible, isDayPending],
  ];

  // Third value should be the Pending attribute
  // Drone connection is checked in the useDroneCheckService (reason for no drone pending)
  const softwareChecks = [
    ['Drone Connected', isDroneConnected, !isDroneConnected],
    ['Battery Level', isBatteryLevelEnough, !isBatteryLevelEnough],
    ['Drone Health', !areHealthChecksFailing, areChecksPending],
    ['Internet Connection', isInternetConnectionLive, !isInternetConnectionLive],
  ];

  const [systemConditionsFailed, setSystemConditionsFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const onCancelClick = () => {
    GenericService.FlightReportService.sendDroneCommand(droneId, missionId, 'cancel_take_off');
    navigate('/schedule');
  };

  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const onStartClick = () => {
    setIsPopupVisible(true);
  };

  const onAdminStartConfirm = () => {
    // Create flight report
    const newFlightReport = new FlightReport();
    newFlightReport.droneId = droneId;
    newFlightReport.missionId = missionId;
    newFlightReport.executeImmediate = true;
    newFlightReport.executeAt = new Date().toISOString();

    GenericService.FlightReportService.saveFlightReport(newFlightReport);

    // Launch drone
    GenericService.FlightReportService.sendDroneCommand(droneId, missionId, 'take_off');
    // Navigate to the dashboard with the assigned drone
    navigate(`/?droneId=${droneId}`);
  };

  const handlePopupConfirm = () => {
    setIsPopupVisible(false);
    onAdminStartConfirm();
  };

  const handlePopupClose = () => {
    setIsPopupVisible(false);
  };

  // Blocks the "Go Back" browser arrow
  useEffect(() => {
    const disableBackButton = (e) => {
      e.preventDefault();
      window.history.forward();
    };

    // Listen for the popstate event
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', disableBackButton);

    // Clean up to prevent memory leaks
    return () => window.removeEventListener('popstate', disableBackButton);
  }, []);

  // Gets data from drone
  useEffect(() => {
    // console.log('Demo log for fake data refresh - comment me or uncomment me to see results for new data simulation');
    if (!isLoading) {
      setSystemConditionsFailed(false);
    }
    if (errors.length > 0 || weatherErrors.length > 0 || areHealthChecksFailing) {
      setSystemConditionsFailed(true);
      setIsLoading(false);
      return;
    }
    const checkPendingStateSoftwareChecks = (arr) => arr.every((item) => item[2] === true);
    setIsLoading(checkPendingStateSoftwareChecks(softwareChecks));
  }, [telemetryData]);

  // This is to apply delay to the weather checks loading
  useEffect(() => {
    if (!isPending) {
      setIsRainLow(isLightRain);
      setIsRainPending(false);

      setTimeout(() => {
        setIsWindLight(isLightWind);
        setIsWindPending(false);
      }, 3000);
      setTimeout(() => {
        setIsVisible(isDay);
        setIsDayPending(false);
        setIsWeatherPending(false);
      }, 5000);
    }
  }, [isPending]);

  return (
    <PageWrapper>
      <div className="pl-4 w-full">
        <h2 className="ml-10 mt-10 col-span-2">Automatic Checks</h2>
        <div className="flex flex-col md:flex-row justify-center items-start mt-5">
          <div className="flex-1 ml-8 mt-5 mb-10">
            <HorizontalStepper steps={softwareChecks} widthPercentage="80" stepsTitle="Software Inspection" />
          </div>
          <div className="flex-1 mt-5 mb-10">
            <HorizontalStepper steps={externalConditions} widthPercentage="80" stepsTitle="External Conditions" />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ml-10">
          {!isLoading && systemConditionsFailed && !isWeatherPending ? (
            <>
              <div className="col-span-1">
                <DroneAutomaticCheck
                  checkName="Software Errors"
                  checkDescription="Testing Description of the check"
                  isLoading={isLoading}
                  isSuccessful={!systemConditionsFailed && weatherErrors.length === 0}
                  errors={errors}
                />
              </div>
              <div className="col-span-1">
                <DroneAutomaticCheck
                  checkName="Wheather errors"
                  checkDescription="Testing Description of the check"
                  isLoading={isLoading}
                  isSuccessful={!systemConditionsFailed && weatherErrors.length === 0}
                  errors={weatherErrors}
                />
              </div>
              <div className="col-span-1 md:col-span-2 flex justify-center mb-2 mr-[80px] space-x-4">
                <Button text="CANCEL" buttonType="cancelOnly" onClick={onCancelClick} />
                {isAdmin && isDroneConnected ? (
                  <Button text="ADMIN START" buttonType="start-admin" onClick={onStartClick} />
                ) : null}
              </div>

              {isPopupVisible && <AlertAdminPopUp onConfirm={handlePopupConfirm} onClose={handlePopupClose} />}
            </>
          ) : (
            <>
              <div className="col-span-1 mt-[100px]">
                <hr className="h-px mt-1 mb-10 my-1 border-0" />
                <CenteredMapView missionId={missionId} />
              </div>
              <div className="col-span-1 mt-[80px]">
                <DroneAutomaticCheck
                  checkName="Software Checks"
                  checkDescription="Testing Description of the check"
                  isLoading={isLoading || isWeatherPending}
                  isSuccessful={!systemConditionsFailed}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </PageWrapper>
  );
}

export default AutomaticChecks;
