/* eslint-disable class-methods-use-this */
class DroneCheckService {
  async getSoftwareChecks(telemetryData) {
    try {
      const isDroneConnected = this.isDroneConnected(telemetryData);
      const isBatteryLevelEnough = this.isBatteryLevelEnough(telemetryData);
      const isInternetConnectionLive = this.isInternetConnectionLive(telemetryData);
      const areChecksPending = this.areChecksPending(telemetryData);
      const areHealthChecksFailing = this.areHealthChecksFailing(telemetryData);
      const droneErrors = this.getErrorMessages(
        isDroneConnected,
        isBatteryLevelEnough,
        isInternetConnectionLive,
        areHealthChecksFailing,
        telemetryData
      );
      return {
        isDroneConnected,
        isBatteryLevelEnough,
        isInternetConnectionLive,
        areChecksPending,
        areHealthChecksFailing,
        droneErrors,
      };
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  isDroneConnected(telemetryData) {
    return telemetryData != null;
  }

  isBatteryLevelEnough(telemetryData) {
    return telemetryData.battery[0].remaining_percent > 20;
  }

  isInternetConnectionLive(telemetryData) {
    return telemetryData.status[0].internet_connected;
  }

  areChecksPending(telemetryData) {
    const healthChecks = telemetryData.health[0];

    return Object.values(healthChecks).some((category) => category.some((check) => check.pending));
  }

  areHealthChecksFailing(telemetryData) {
    const healthChecks = telemetryData.health[0];

    return Object.values(healthChecks).some((category) => category.some((check) => !check.status));
  }

  getHealthChecksErrorMessages(telemetryData) {
    const healthChecks = telemetryData.health[0];
    const messages = [];

    Object.keys(healthChecks).forEach((key) => {
      healthChecks[key].forEach((entry) => {
        if (entry.message) {
          messages.push(entry.message);
        }
      });
    });

    return messages;
  }

  getErrorMessages(
    isDroneConnected,
    isBatteryLevelEnough,
    isInternetConnectionLive,
    areHealthChecksFailing,
    telemetryData
  ) {
    if (!isDroneConnected) {
      return ['Drone not connected'];
    }
    const errors = [];
    if (!isBatteryLevelEnough) {
      errors.push('Low Battery');
    }
    if (!isInternetConnectionLive) {
      errors.push('No or unstable Internet connection');
    }
    if (areHealthChecksFailing) {
      const healthErrors = this.getHealthChecksErrorMessages(telemetryData);
      healthErrors.forEach((err) => errors.push(err));
    }

    return errors;
  }
}

export default DroneCheckService;
