import { useState, useEffect } from 'react';
import WeatherService from '../services/Weather/weather.service';

export default function useWeatherAPI() {
  const [isLoadingWeather, setIsLoadingWeather] = useState(true);
  const [isLightRain, setIsLightRain] = useState(null);
  const [isLightWind, setIsLightWind] = useState(null);
  const [isDay, setIsDay] = useState(null);
  const [isPending, setIsPending] = useState(true);
  const [weatherErrors, setWeatherErrors] = useState([]);

  useEffect(() => {
    const weatherService = new WeatherService();
    async function fetchWeather() {
      try {
        const data = await weatherService.getCurrentWeather();
        if (data) {
          setIsLightRain(data.isLightRain);
          setIsLightWind(data.isLightWind);
          setIsDay(data.isDay);
          setWeatherErrors(data.weatherErrors);
        }
      } catch (err) {
        setWeatherErrors(err);
      } finally {
        setIsLoadingWeather(false);
        setIsPending(false);
      }
    }

    fetchWeather();
  }, []);

  return { isLightRain, isLightWind, isDay, isLoadingWeather, isPending, weatherErrors };
}
