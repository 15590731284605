import React from 'react';
import { TailSpin } from 'react-loader-spinner';

/**
 * This component renders the Loader element
 * which displays a loader animation (can be used for async functions)
 */

function Loader() {
  return (
    <TailSpin
      height="80"
      width="80"
      color="#1560BD"
      ariaLabel="tail-spin-loading"
      radius="1"
      wrapperStyle={{}}
      wrapperClass=""
    />
  );
}
export default Loader;
