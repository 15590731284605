import React, { useState } from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './MinimizedReportLog.scss';

export default function MinimizedReportLog({ errors }) {
  const [openedErrors, setOpenedErrors] = useState({});

  const toggleErrorDescription = (index) => {
    setOpenedErrors((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <div className="minimized-report-log-container">
      <div className="minimized-report-log-header">We found some safety problems with your mission.</div>
      <div className="minimized-report-log-sub-header">Please, solve the problems below to save the mission:</div>
      <div className="minimized-report-log-content">
        {errors.map((error, index) => (
          <div className={`minimized-report-log-item border ${openedErrors[index] ? 'opened' : ''}`}>
            <div
              role="button"
              tabIndex={0}
              className="minimized-error-header"
              onClick={() => toggleErrorDescription(index)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  toggleErrorDescription(index);
                }
              }}
            >
              {openedErrors[index] ? (
                <>
                  <div>{error.header}</div>
                  <div className="minimized-error-header-message">{error.message}</div>
                </>
              ) : (
                error.header
              )}
            </div>
            <FontAwesomeIcon
              icon={solid('caret-down')}
              onClick={() => toggleErrorDescription(index)}
              className="minimized-toggle-icon"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
