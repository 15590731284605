import React from 'react';
import DroneCard from '../../components/Card/DroneCard/DroneCard';

export default function DroneSelection({ drones, selectedDrone, onDroneSelect, maxHeightClass }) {
  const sortByName = (unorderedDrones) => unorderedDrones.sort((d1, d2) => d1.name.localeCompare(d2.name));
  const sortedDrones = sortByName(drones);

  return (
    <div className={`overflow-y-auto shadow-lg flex flex-col space-y-4 p-4 rounded-lg ${maxHeightClass}`}>
      {sortedDrones.map((drone) => (
        <div
          key={drone.droneId}
          role="none"
          className={`border rounded cursor-pointer ${
            selectedDrone && selectedDrone.droneId === drone.droneId ? 'border-blue' : 'border-white'
          }`}
          onClick={() => {
            onDroneSelect(drone);
          }}
        >
          <DroneCard drone={drone} />
        </div>
      ))}
    </div>
  );
}
