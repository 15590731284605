import DroneService from './Drones/drone.service';
import MissionService from './Missions/mission.service';
import FlightReportService from './Drones/flightReport.service';
import AppStoreService from './Apps/appStore.service';
import MissionAppService from './Apps/missionApp.service';
import WaypointService from './Missions/waypoint.service';
import FlightReportAppService from './Apps/flightReportApp.service';

const GenericService = {
  DroneService,
  MissionService,
  WaypointService,
  FlightReportService,
  AppStoreService,
  MissionAppService,
  FlightReportAppService,
};

export default GenericService;
