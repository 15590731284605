import api from '../api';

const getUsers = () =>
  api
    .get('/users')
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response.data));

const getUser = (user) =>
  api
    .get(`/users/${user.userId}`, user)
    .then((res) => res)
    .catch((err) => Promise.reject(err.response.data));

const getUserRole = (user) =>
  api
    .get(`/users/${user.userId}/role`, user)
    .then((res) => res)
    .catch((err) => Promise.reject(err.response.data));

const saveUser = (user) =>
  api
    .post(`/users`, user)
    .then((res) => res)
    .catch((err) => Promise.reject(err.response.data));

const updateUser = (user) =>
  api
    .put(`/users/${user.userId}`, user)
    .then((res) => res)
    .catch((err) => Promise.reject(err.response.data));

const UserService = {
  getUser,
  getUserRole,
  getUsers,
  saveUser,
  updateUser,
};

export default UserService;
