import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import ManageMissionCard from '../../../../components/Card/ManageMissionCard/ManageMissionCard';
import { MissionsContext } from '../../Missions';
import { Mission } from '../../../../classes/Mission';
import TokenService from '../../../../services/Authentication/token.service';

export default function MissionBar() {
  const { missions, setMissions, setSelectedMissionId, setSelectedWaypoint, setSelectAppPopup } =
    useContext(MissionsContext);
  const [currentUser, setCurrentUser] = useState(null);

  const getCurrentUser = async () => {
    try {
      const userData = TokenService.getUser();
      setCurrentUser(userData);
    } catch (error) {
      console.log('Failed to fetch current user:', error);
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  const addNewMission = () => {
    if (currentUser) {
      setSelectAppPopup(true);
      const newMission = new Mission({
        userId: currentUser.userId,
        name: '',
        waypoints: [],
        newMission: true,
      });

      const updatedMissions = [newMission, ...missions];
      setMissions(updatedMissions);

      setSelectedMissionId(newMission.missionId);
      setSelectedWaypoint(null);
    }
  };

  if (missions) {
    const sortedMissions = missions.slice().sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    return (
      <>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <button
          type="button"
          className="bg-white rounded text-white"
          onClick={addNewMission}
          disabled={window._.some(missions, { name: '' })}
        >
          <FontAwesomeIcon color="blue" icon={solid('plus')} />
        </button>
        <div className="cards-grid">
          {sortedMissions.length > 0 &&
            sortedMissions.map((mission) => (
              <ManageMissionCard key={mission.missionId} missionId={mission.missionId} />
            ))}
        </div>
      </>
    );
  }

  return null;
}
