import React from 'react';
import './DataBar.scss';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

// Import components
import StatusIndicator from '../../../../components/StatusIndicator/StatusIndicator';
import DataItem from './DataItem/DataItem';
import CoordinatesBox from './CoordinatesBox/CoordinatesBox';
import useSignalRConnection from '../../../../hooks/useSignalRConnection';

export default function DataBar({ selectedDrone }) {
  const { telemetryData } = useSignalRConnection(selectedDrone.droneId);

  const data = [
    {
      id: 1,
      icon: <FontAwesomeIcon icon={regular('battery-half')} />,
      title: 'Battery',
      data: telemetryData ? telemetryData.battery[0].voltage_v : 'Unknown',
      dataUnit: 'V',
    },
    // {
    //   id: 2,
    //   icon: <FontAwesomeIcon icon={regular('timer')} />,
    //   title: 'Speed',
    //   data: telemetryData ? (realTimeData.cu ? realTimeData.cu : 'Unknown') : 'Unknown',
    //   dataUnit: 'km/h',
    // },
    {
      id: 3,
      icon: <FontAwesomeIcon icon={regular('compass')} />,
      title: 'Heading',
      data: telemetryData
        ? telemetryData.compass[0].heading_deg
          ? telemetryData.compass[0].heading_deg
          : 'Unknown'
        : 'Unknown',
      dataUnit: 'deg',
    },
    {
      id: 4,
      icon: <FontAwesomeIcon icon={regular('gauge-high')} />,
      title: 'Velocity X',
      data: telemetryData ? telemetryData.velocity[0].east_m_s : 'Unknown',
      dataUnit: 'm/s',
    },
    {
      id: 5,
      icon: <FontAwesomeIcon icon={regular('gauge-high')} />,
      title: 'Velocity Y',
      data: telemetryData ? telemetryData.velocity[0].north_m_s : 'Unknown',
      dataUnit: 'm/s',
    },
    {
      id: 6,
      icon: <FontAwesomeIcon icon={regular('gauge-high')} />,
      title: 'Velocity Z',
      data: telemetryData ? telemetryData.velocity[0].down_m_s : 'Unknown',
      dataUnit: 'm/s',
    },
    {
      id: 7,
      icon: <FontAwesomeIcon icon={regular('map-pin')} />,
      title: 'Coordinates',
    },
  ];

  return (
    <div className="data-bar">
      <div className="top">
        <div className={`border-left ${telemetryData !== null ? 'online' : 'offline'}`} />
        <p className="name">Drone</p>
        <StatusIndicator number={2} status={telemetryData !== null ? 'online' : 'offline'} />
        <p className="route">Inspection round 1</p>
      </div>
      <div className="bottom">
        {data.map((item) => (
          <DataItem key={item.id} icon={item.icon} title={item.title} data={item.data} dataUnit={item.dataUnit} />
        ))}
        <CoordinatesBox
          lat={telemetryData?.gps[0].latitiude}
          long={telemetryData?.gps[0].longtitude}
          alt={telemetryData?.gps[0].relative_altitude_m}
        />
      </div>
    </div>
  );
}
