import React, { useState } from 'react';

function AlertAdminPopUp({ onConfirm, onClose }) {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsCheckboxChecked(event.target.checked);
  };

  const handleConfirmClick = () => {
    if (isCheckboxChecked) {
      onConfirm();
    } else {
      alert('Please check the checkbox to confirm.');
    }
  };

  return (
    <div className="popup flex items-center justify-center fixed top-0 left-0 w-full h-full bg-black bg-opacity-50">
      <div className="popup-content bg-white p-6 rounded shadow-md text-center">
        <h4 className="text-red-600 mb-4">This is a dangerous flight, are you sure you want to proceed?</h4>
        <label htmlFor="confirmationCheckbox" className="block mb-4">
          <input
            id="confirmationCheckbox"
            type="checkbox"
            checked={isCheckboxChecked}
            onChange={handleCheckboxChange}
            className="mr-2"
          />
          I agree to proceed
        </label>
        <button type="button" onClick={handleConfirmClick} className="bg-blue text-white py-2 px-4 rounded mr-2">
          Start
        </button>
        <button type="button" onClick={onClose} className="bg-gray text-white py-2 px-4 rounded">
          Close
        </button>
      </div>
    </div>
  );
}

export default AlertAdminPopUp;
