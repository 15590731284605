import { useState, useEffect } from 'react';
import DroneCheckService from '../services/AutomaticChecks/droneChecks.service';

export default function useDroneCheckService(telemetryData) {
  const [isDroneConnected, setIsDroneConnected] = useState(null);
  const [isBatteryLevelEnough, setIsBatteryLevelEnough] = useState(null);
  const [isInternetConnectionLive, setIsInternetConnectionLive] = useState(null);
  const [areChecksPending, setAreChecksPending] = useState(true);
  const [areHealthChecksFailing, setAreHealthChecksFailing] = useState(null);
  const [errors, setErrors] = useState(['Drone not connected']);

  useEffect(() => {
    if (!telemetryData) {
      setIsDroneConnected(false);
      setIsBatteryLevelEnough(false);
      setIsInternetConnectionLive(false);
      setAreChecksPending(false);
      setAreHealthChecksFailing(true);
      console.log(errors);
      return;
    }
    async function fetchSoftwareChecks() {
      try {
        const droneCheckService = new DroneCheckService();
        const data = await droneCheckService.getSoftwareChecks(telemetryData);
        if (data) {
          setIsDroneConnected(data.isDroneConnected);
          setIsBatteryLevelEnough(data.isBatteryLevelEnough);
          setIsInternetConnectionLive(data.isInternetConnectionLive);
          setAreChecksPending(data.areChecksPending);
          setAreHealthChecksFailing(data.areHealthChecksFailing);
          setErrors(data.droneErrors);
        }
      } catch (err) {
        console.error(err);
      }
    }

    fetchSoftwareChecks();
  }, [telemetryData]);

  return {
    isDroneConnected,
    isBatteryLevelEnough,
    isInternetConnectionLive,
    errors,
    areChecksPending,
    areHealthChecksFailing,
  };
}
