import React from 'react';

const COLORS = {
  primary: {
    backgroundColor: '#E7F7FE',
    color: '#4885CC',
  },
  success: {
    backgroundColor: '#E4F3EE',
    color: '#4C9C7A',
  },
};

export default function Badge({ type = 'primary', children }) {
  return (
    <div style={COLORS[type]} className="text-sm leading-sm px-3 py-1 rounded">
      {children}
    </div>
  );
}
