import React, { useEffect } from 'react';
import './AppDetails.scss';
import '../../shared.scss';
import { useLocation } from 'react-router-dom';
import GenericService from '../../../services/generic.service';

function AppDetailPage() {
  const location = useLocation();
  const { app } = location.state;

  const getBackToAppstore = () => {
    setTimeout(() => {
      window.location.href = '/appstore';
    }, 10);
  };

  const addFavoriteApps = async () => {
    await GenericService.AppStoreService.AddFavoriteApp(app.id);
    getBackToAppstore();
  };

  const deleteFavoriteApps = async () => {
    await GenericService.AppStoreService.DeleteFavoriteApp(app.id);
    getBackToAppstore();
  };

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="app-store-details">
      <button
        type="button"
        className="bg-transparent text-grey font-semibold py-2 px-4 border border-grey hover:border-transparent rounded-3xl"
        onClick={getBackToAppstore}
      >
        &lt;
      </button>
      <div className="pl-14">
        <div className="heading app-header w-full">
          <img className="image-logo" src={app.logoUrl} alt="visual representation" />
          <h2>{app.name}</h2>
        </div>
        <div className="app-details-container">
          <div className="description-apps">
            <div className="short">
              <p>{app.description}</p>
              <a href={app.url} target="_blank" rel="noopener noreferrer">
                <span className="underline-link">lees meer &gt; </span>
              </a>
            </div>
            <div className="long">
              <p>{app.description}</p>
            </div>
          </div>
          <div className="image-container">
            <img className="image rounded-xl" src={app.logoUrl} alt="visual representation" />
          </div>
        </div>
        <div>
          <div className="add-favorite">
            <button type="button" className="add-favorite-btn rounded-xl" onClick={addFavoriteApps}>
              Toevoegen aan favorieten
            </button>
          </div>
          <div>
            <button type="button" className="delete-favorite-btn rounded-xl" onClick={deleteFavoriteApps}>
              Delete from favorites
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppDetailPage;
