/* eslint-disable */
import React, {useEffect, useState} from 'react';
import '../shared.scss';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import GenericService from '../../services/generic.service';
import FlightReportDetail from './FlightReportDetail';
import Badge from '../../components/App/Badge/Badge';

export default function FlightReportList() {
  const [flightReports, setFlightReports] = useState([]);
  const [selectedFlightReport, setSelectedFlightReport] = useState(null);
  const [selectedFlightReportId, setSelectedFlightReportId] = useState(null);
  const [searchValue, setSearchValue] = useState(null);

  const getFlightReports = () => {
    GenericService.FlightReportService.getFlightReports(searchValue).then((response) => {
      const sortedReports = response.slice().sort((a, b) => {
        return new Date(b.executeAt) - new Date(a.executeAt);
      });
      setFlightReports(sortedReports);

      if (sortedReports.length > 0) {
        setSelectedFlightReportId(sortedReports[0].flightReportId);
      }
    });
  };

  const getFlightReport = (flightReportId) => {
    GenericService.FlightReportService.getFlightReport(flightReportId).then((response) => {
      setSelectedFlightReport(response.data);
    });
  };

  useEffect(() => {
    if (selectedFlightReportId) {
      getFlightReport(selectedFlightReportId);
    }
  }, [selectedFlightReportId]);

  useEffect(() => {
    getFlightReports();
  }, [searchValue]);

  return (
    <PageWrapper>
      <div className="container overflow-auto -mt-8">
        <div className="grid grid-cols-12 gap-4 mr-5">
          {/* Mission sidebar */}
          <div className="col-span-3">
            <div className="max-w-sm p-2 bg-white rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
              {/* Title */}
              <h6 className="mb-1 text-1xl font-bold tracking-tight text-gray-900 dark:text-white">All missions</h6>

              <hr style={{ backgroundColor: 'rgb(229 231 235)' }} className="h-px my-1 border-0 dark:bg-gray-700" />

              {/* Search input */}
              <div className="mb-2">
                <input
                  className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                  id="inline-full-name"
                  type="text"
                  placeholder="Search flight reports"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>

              <div style={{ overflowY: 'auto', height: '75vh' }}>
                {flightReports?.map((flightReport, index) => (
                  <div
                    key={index}
                    style={{
                      backgroundColor: '#FAFAFF',
                      borderColor: selectedFlightReportId === flightReport.flightReportId ? '#6F9FCF' : '#FAFAFF',
                    }}
                    className="rounded-lg py-2 px-4 mb-2 cursor-pointer border-2 select-none"
                    onClick={() => setSelectedFlightReportId(flightReport?.flightReportId)}
                  >
                    <div className="grid grid-cols-12 gap-4">
                      <div className="font-bold col-span-8">{flightReport?.mission?.name}</div>
                      <div className="col-span-4 justify-end flex items-center">
                        <Badge type="success">Success</Badge>
                      </div>
                    </div>

                    <div style={{ color: 'rgb(107 114 128)' }}>
                      <FontAwesomeIcon style={{ fontSize: '15px' }} icon={regular('calendar-days')} />{' '}
                      {new Date(flightReport?.executeAt).toLocaleDateString([], { hour: '2-digit', minute: '2-digit' })} &nbsp;
                      <FontAwesomeIcon style={{ fontSize: '14px' }} icon={regular('clock')} /> 20m
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="col-span-9">
            <div className="p-2 bg-white px-3 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
              {selectedFlightReportId ? (
                <FlightReportDetail flightReport={selectedFlightReport} flightReportId={selectedFlightReportId} />
              ) : (
                <>Select flight report to get started</>
              )}
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
}
