/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import '../shared.scss';
import './AppStore.scss';
import AppCard from '../../components/AppStore/AppStore/appCards';
import GenericService from '../../services/generic.service';


export default function AppStore() {
  const [apps, setApps] = useState([]);
  const [favoriteApps, setFavoriteApps] = useState([]);
  const containerRef = useRef(null);

  const getFavoriteStaticApps = (appsList) => {
    GenericService.AppStoreService.GetFavoriteApps()
      .then((response) => {
        const favoriteApps = response.map((favorite) => {
          return appsList.find((app) => app.id === favorite.app.id);
        });
        setFavoriteApps(favoriteApps);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft -= 170; // Adjust the scroll distance as needed
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft += 170; // Adjust the scroll distance as needed
    }
  };

  useEffect(() => {
    GenericService.AppStoreService.GetAllApps().then((apps) => {
      setApps(apps);
      getFavoriteStaticApps(apps);
    });
  }, []);

  return (
    <div className="app-store pt-8 pb-16">
      <div className="heading app-store-heading">
        <h2>App store</h2>
      </div>
      <div className="all-apps-container">
        <p className="text-3xl mb-5 app-store-heading">Opgeslagen</p>
        <div className="app-card-scroll-container flex scroll-smooth" ref={containerRef}>
          {Object.values(favoriteApps).length === 0 && <p>Nog geen favoriete apps toegevoegd!</p>}
          {Object.values(favoriteApps).map((app) => (
            <AppCard key={app.id} app={app} />
          ))}
        </div>
        <div className="btn-placement">
          <div>
            <button type="button" className="scroll-button left-btn" onClick={scrollLeft}>
              &lt;
            </button>
            <button type="button" className="scroll-button right-btn" onClick={scrollRight}>
              &gt;
            </button>
          </div>
        </div>

        <div className="center">
          <div className="app-section">
            <p className="text-3xl mb-10 app-store-heading">Alle apps</p>
            <div className="app-card-container">
              {Object.values(apps).map((app) => (
                <AppCard key={app.id} app={app} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
