// import './Map.scss';

/* eslint-disable */
// We need the following lines to keep babel from messing up the library...
import mapboxgl from 'mapbox-gl';

import MapGL, {
  // Marker,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
} from 'react-map-gl';
import React, { useRef } from 'react';
import DroneSource from './DroneSource';
import { useEffect } from 'react';


// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const initialViewState = {
  longitude: 4.55, // 5.4697225,
  latitude: 52.23, // 51.441642,
  // zoom: 3.5,
  // pitch: 40,
  // maxPitch: 70,
  zoom: 6.5, // 35
};

export default function DashboardMap({ mapStyle, drones, selectedDrone }) {
  const mapRef = useRef(null);

  const flyTo = (longitude, latitude, isZoomIn) => {
    if (mapRef.current) {
      mapRef.current.flyTo({
        center: [longitude, latitude],
        speed: 0.9,
        zoom: isZoomIn ? 16 : 3.5,
        duration: 3000,
      });
    }
  };

  const droneFocusHandler = (longitude, latitude, isZoomIn) => {
    if (!longitude || !latitude) return;
    flyTo(longitude, latitude, isZoomIn);
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((pos) => {
      const { longitude, latitude } = pos.coords;
      flyTo(longitude, latitude, true);
    });
  }, []);

  return (
    <>
      <MapGL
        ref={mapRef}
        initialViewState={initialViewState}
        mapStyle={`mapbox://styles/mapbox/${mapStyle}`}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      >
        <GeolocateControl position="bottom-right" />
        <FullscreenControl position="bottom-right" />
        <NavigationControl position="bottom-right" />
        <ScaleControl />

        {drones.map((drone) => (
          <DroneSource
            key={drone.droneId}
            drone={drone}
            selectedDrone={selectedDrone}
            onDroneFocusing={droneFocusHandler}
          />
        ))}
      </MapGL>
      <div className="overlay" />
    </>
  );
}
