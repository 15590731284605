import React, { useContext, useEffect, useState } from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import { MissionsContext } from '../../../pages/Missions/Missions';
import Input from '../../Input/Input';
import GenericService from '../../../services/generic.service';
import RoutePlanningService from '../../../services/routeplanning.service';
import { Waypoint } from '../../../classes/Waypoint';
import { Mission } from '../../../classes/Mission';
import { validateMissionSafetyErrors } from '../../../helpers/missionValidator';
import checkSameCoordinatesValidator from '../../../helpers/checkSameCoordinatesValidator';
import MissionAppCard from '../../AppStore/Missions/MissionAppCard';
import MissionApp from '../../../classes/MissionApp';
import './ManageMissionCard.scss';

export default function ManageMissionCard({ missionId }) {
  const {
    missions,
    setMissions,
    setMissionValidationErrors,
    editMissionId,
    setEditMissionId,
    selectedMissionId,
    setSelectedMissionId,
    setSelectedWaypoint,
    selectedWaypoint,
    waypoints,
    setWaypoints,
    showAltitudeInput,
    setShowAltitudeInput,
    apps,
    favoriteApps,
    selectAppPopup,
    setSelectAppPopup,
    selectedApps,
    setSelectedApps,
  } = useContext(MissionsContext);

  const [mission, setMission] = useState(window._(missions).find({ missionId }));
  const [waypointAltitude, setWaypointAltitude] = useState('');

  const [widthFOV, setWidthFOV] = useState(14);
  const [heightFOV, setHeightFOV] = useState(10);
  const [speed, setSpeed] = useState(3);
  const [sideOverlap, setSideOverlap] = useState(0.2);
  const [frontalOverlap, setFrontalOverlap] = useState(0.1);
  const [showApps, setShowApps] = useState(false);

  let missionNameInput = null;

  const getMissions = (selectFirstMission = false) => {
    GenericService.MissionService.getMissions().then((responseMissions) => {
      setMissions(responseMissions);

      if (responseMissions.length === 0 || !selectFirstMission) {
        setSelectedMissionId(0);
      } else if (selectFirstMission) {
        setSelectedMissionId(responseMissions[0].missionId);
      }
    });
  };

  const getMissionWaypoints = () => {
    GenericService.MissionService.getMissionWaypoints(selectedMissionId).then((response) => {
      setWaypoints(response.data);
    });
  };

  useState(() => {
    if (selectedMissionId) {
      getMissionWaypoints();
    }
  }, [waypoints]);

  // If the missions are changed (so new missions from the API call), get the new mission for this card using the given "missionId" prop.
  useEffect(() => {
    setMission(window._(missions).find({ missionId }));
  }, [missions]);

  // If the mission was changed and the name is empty, select that mission (empty names are not allowed).
  useEffect(() => {
    if (mission.name === '') {
      setEditMissionId(missionId);
    }
  }, [mission]);

  // If the current mission card is edited, focus on the name input and select the edited mission (so the card will toggle open).
  useEffect(() => {
    if (editMissionId === missionId) {
      missionNameInput.focus();
      setSelectedMissionId(missionId);
    }
  }, [editMissionId]);

  useEffect(() => {
    if (!showAltitudeInput) {
      setWaypointAltitude('');
    }
  }, [showAltitudeInput]);

  useEffect(() => {
    setShowAltitudeInput(false);
    setMissionValidationErrors([]);
  }, [selectedMissionId]);

  const createMission = async () => {
    const missionValidationErrors = validateMissionSafetyErrors(mission);
    if (missionValidationErrors.length > 0) {
      setMissionValidationErrors(missionValidationErrors);
      return;
    }
    let responseData = null;
    await GenericService.MissionService.createMission(mission).then((response) => {
      if (response.status === 201) {
        window.toast.warning('By default, the altitude for new waypoints is preset to 10. Be careful!');
        window.toast.success('The mission was created successfully.');
        getMissions(false);
        responseData = response.data;

        setMissionValidationErrors([]);
      } else {
        window.toast.error('Something went wrong while creating the mission, please try again.');
      }
    });
    const missionAppPromise = selectedApps.map((selectedApp) => {
      const missionApp = new MissionApp({
        missionId: responseData.missionId,
        appId: selectedApp.id,
      });

      return GenericService.MissionAppService.createMissionApp(missionApp);
    });

    await Promise.all(missionAppPromise);

    getMissions(false);
  };

  const updateMissionApps = () => {
    const appsToDelete = mission.missionApps.filter(
      (missionApp) => !selectedApps.some((app) => app.id === missionApp.app.id)
    );

    appsToDelete.forEach(async (app) => {
      await GenericService.MissionAppService.deleteMissionApp(app.missionAppId);
    });

    const appsToAdd = selectedApps.filter(
      (app) => !mission.missionApps.some((missionApp) => missionApp.app.id === app.id)
    );

    appsToAdd.forEach(async (app) => {
      const missionApp = new MissionApp({
        missionId: mission.missionId,
        appId: app.id,
      });
      await GenericService.MissionAppService.createMissionApp(missionApp);
    });

    getMissions(false);
  };

  const updateMission = () => {
    const missionValidationErrors = validateMissionSafetyErrors(mission);
    if (missionValidationErrors.length > 0) {
      setMissionValidationErrors(missionValidationErrors);
      return;
    }

    GenericService.MissionService.updateMission(mission).then((response) => {
      if (response.status === 200) {
        window.toast.success('The mission was updated successfully.');
        setEditMissionId(0);
        setMission(response.data);

        updateMissionApps();
        setMissionValidationErrors([]);

        setShowAltitudeInput(false);
        getMissions(false);
      } else {
        window.toast.error('Something went wrong while updating the mission, please try again.');
      }
    });
  };

  const deleteMission = () => {
    setMissionValidationErrors([]);
    if (mission.newMission) {
      const newMissions = missions.filter((missionFilter) => missionFilter.missionId !== missionId);
      setMissions(newMissions);
      setSelectedMissionId(0);
    } else {
      GenericService.MissionService.deleteMission(missionId).then((response) => {
        if (response.status === 200) {
          window.toast.success('The mission was removed successfully.');
          getMissions(false);
        } else {
          window.toast.error('Something went wrong while removing the mission, please try again.');
        }
      });
    }
  };

  const handleRenameMission = (name) => {
    const updatedMissions = missions.map((m) => {
      if (m.missionId === missionId) {
        return new Mission({ ...m, name });
      }
      return m;
    });

    setMissions(updatedMissions);
    setMission(new Mission({ ...mission, name }));
  };

  const handleSetAltitude = () => {
    if (!Number.isNaN(waypointAltitude) && waypointAltitude > 0 && waypointAltitude < 50) {
      window.toast.info('You have modified altitude for all waypoints. Do not forget to save the mission!');
      const updatedWaypoints = waypoints.map((waypoint, index) => {
        const updatedAltitude = waypointAltitude !== '' ? Number(waypointAltitude) : null;
        return new Waypoint({
          ...waypoint,
          altitude: updatedAltitude,
          order: index + 1,
        });
      });

      setWaypoints(updatedWaypoints);
    } else {
      window.toast.error('General altitude should be a positive number greater than 0 and smaller than 50');
    }
  };

  const handleRemoveWaypoint = (waypoint) => {
    GenericService.WaypointService.deleteWaypoint(waypoint.waypointId);
    const updatedWaypointsResponse = GenericService.MissionService.getMissionWaypoints(missionId);
    const updatedWaypoints = updatedWaypointsResponse.data;

    setWaypoints(updatedWaypoints);
    const newWaypoints = [
      ...waypoints
        .filter((wp) => wp.order !== waypoint.order)
        .map(
          (wp, index) =>
            new Waypoint({
              ...wp,
              order: index + 1,
            })
        ),
    ];

    if (waypoint.order === selectedWaypoint?.order) {
      setSelectedWaypoint(null);
    } else if (waypoint.order < selectedWaypoint?.order) {
      const newSelectedWaypoint = window._.find(newWaypoints, { order: selectedWaypoint.order - 1 });
      setSelectedWaypoint(newSelectedWaypoint);
    }

    setWaypoints(newWaypoints);
  };

  const handleGoBackHome = () => {
    setWaypoints(() => [
      ...waypoints,
      new Waypoint({
        missionId: selectedMissionId,
        name: null,
        order: waypoints.length + 1,
        longitude: waypoints[0].longitude,
        latitude: waypoints[0].latitude,
        waypointTypeDefinitionId: 0,
      }),
    ]);
  };

  const handleCreateSnakePath = () => {
    const snakePathData = {
      mission,
      properties: {
        fovWidth: Number(widthFOV),
        fovHeight: Number(heightFOV),
        altitude: waypoints.length > 0 ? waypoints[0].altitude : 0, // Assuming all waypoints have the same altitude
        overlapFrontal: Number(frontalOverlap),
        overlapSide: Number(sideOverlap),
        Speed: Number(speed),
      },
    };

    RoutePlanningService.getSnakePath(snakePathData)
      .then((response) => {
        const { mission: responseMission } = response.data;

        // Convert each waypoint in the response to an instance of the Waypoint class
        const responseWaypoints = responseMission.waypoints.map((wp) => new Waypoint(wp));

        console.log(responseWaypoints);
        setWaypoints(responseWaypoints);
      })
      .catch((error) => {
        // Handle the error
        console.error('Error creating snake path:', error);
      });
  };

  const canShowSnakePathButton = (lEditMissionId, lMissionId, lWaypoints) => {
    const firstWaypoint = window._.find(lWaypoints, { order: 1 });
    const lastWaypoint = window._.find(lWaypoints, { order: lWaypoints.length });

    return (
      lEditMissionId === lMissionId &&
      lWaypoints.length > 1 &&
      firstWaypoint &&
      lastWaypoint &&
      typeof firstWaypoint.sameLocationAs === 'function' &&
      firstWaypoint.sameLocationAs(lastWaypoint)
    );
  };

  return (
    <div>
      <div
        className={`max-w-md p-4 bg-white shadow-lg rounded-lg ${
          missionId === selectedMissionId ? 'border-l-4 border-blue' : ''
        }`}
      >
        <div>
          {selectAppPopup && editMissionId === missionId ? (
            <div
              id="select-modal"
              className="overflow-y-hidden fixed inset-0 flex items-center justify-center z-[999] backdrop-brightness-50"
            >
              <div className="modal relative h-5/6 w-9/12 bg-white mx-auto rounded-lg overflow-y-auto pl-8">
                <div className="modal-box flex line justify-between">
                  <h2 className="text-4xl">Select app</h2>
                  <div className="modal-action modal-action-border">
                    <button
                      type="button"
                      className="close mr-5"
                      data-modal-hide="default-modal"
                      onClick={() => setSelectAppPopup(false)}
                    >
                      &times;
                    </button>
                  </div>
                </div>
                <div className="modal-overlay">
                  <div className="content">
                    <div className="app-container">
                      <p className="mt-4 text-2xl"> Favorite </p>
                      <div className="grid gap-3 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-center items-center">
                        {favoriteApps.length > 0 ? (
                          favoriteApps.map((app) => <MissionAppCard key={app.appId} app={app.app} />)
                        ) : (
                          <p>No favorite app have been added</p>
                        )}
                      </div>
                    </div>
                    <div>
                      <div className="app-container">
                        <button type="button" className="mt-3 text-2xl" onClick={() => setShowApps(!showApps)}>
                          All Apps
                          <FontAwesomeIcon
                            icon={showApps ? faChevronDown : faChevronUp}
                            className="ml-5 pt-4"
                            size="xs"
                          />
                        </button>
                        {showApps && (
                          <div className="grid gap-3 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-center items-center">
                            {apps.map((app) => (
                              <MissionAppCard key={app.id} app={app} />
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="actions">
                  <button
                    type="button"
                    className="bg-blue mb-1 pl-3 pr-3 rounded-lg text-white mt-10"
                    onClick={() => {
                      setSelectAppPopup(false);
                    }}
                  >
                    Confirm selection
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div className="flex justify-between mt-1">
          {editMissionId !== 0 && editMissionId === missionId ? (
            <div>
              <Input
                placeholder="Mission name"
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    handleRenameMission(event.target.value);
                    updateMission();
                  }
                }}
                inputClasses={`mb-3 ${mission.name === '' ? 'border border-solid border-red' : ''} pl-2`}
                handleRef={(input) => {
                  missionNameInput = input;
                }}
                handleChange={(event) => {
                  handleRenameMission(event.target.value);
                }}
                onBlur={() => {
                  handleRenameMission(mission.name);
                }}
                value={mission.name}
              />
              {selectedApps.length > 0 ? (
                selectedApps.map((selectedApp) => (
                  <button type="button" key={selectedApp.id} onClick={() => setSelectAppPopup(true)}>
                    <div className="app-store-card-text">
                      <FontAwesomeIcon icon={solid('tablet-screen-button')} />
                      <p className="ml-5">{selectedApp.name}</p>
                    </div>
                  </button>
                ))
              ) : (
                <button type="button" onClick={() => setSelectAppPopup(true)}>
                  <div className="app-store-card-text">
                    <FontAwesomeIcon icon={solid('tablet-screen-button')} />
                    <p className="ml-5 text-sm">Selecteer een app</p>
                  </div>
                </button>
              )}
            </div>
          ) : (
            <button
              type="button"
              className="break-all text-lg font-semibold w-full text-left"
              onClick={() => {
                setSelectedMissionId(missionId);
                setEditMissionId(0);
                setSelectedWaypoint(null);
                setSelectedApps(mission.missionApps.map((missionApp) => missionApp.app));
              }}
            >
              {mission.name}
            </button>
          )}
          <FontAwesomeIcon
            icon={editMissionId === missionId ? solid('pen-slash') : solid('pen')}
            className="select-none text-blue cursor-pointer mt-1"
            onClick={() => {
              if (editMissionId === missionId) {
                setEditMissionId(0);
                setWaypointAltitude('');
                setShowAltitudeInput(false);
              } else {
                setEditMissionId(missionId);
                setShowAltitudeInput(true);
              }
            }}
          />
        </div>

        {/* Button Change Altitude */}
        {selectedMissionId === missionId && showAltitudeInput && (
          <div className="mt-2 flex items-center">
            <Input
              type="number"
              placeholder="Waypoint Altitude"
              inputClasses="w-full px-2 py-1 border border-gray-300 rounded-md"
              handleChange={(event) => setWaypointAltitude(event.target.value)}
              value={waypointAltitude}
            />
            <button
              type="button"
              className="ml-6 px-4 py-1 bg-blue text-white rounded-md text-sm"
              onClick={handleSetAltitude}
            >
              Set
            </button>
          </div>
        )}

        {/* Button Change Width of FOV */}
        {canShowSnakePathButton(editMissionId, missionId, waypoints) && (
          <div className="mt-2 w-full">
            <p className="text-grey mt-4 mb-1">Width FOV</p>
            <Input
              type="number"
              placeholder="Width of FOV"
              inputClasses="w-full px-2 py-1 border border-gray-300 rounded-md"
              handleChange={(event) => setWidthFOV(event.target.value)}
              value={widthFOV}
            />
          </div>
        )}

        {/* Button Change Height of FOV */}
        {canShowSnakePathButton(editMissionId, missionId, waypoints) && (
          <div className="mt-2 w-full">
            <p className="text-grey mt-4 mb-1">Height FOV</p>
            <Input
              type="number"
              placeholder="Height of FOV"
              inputClasses="w-full px-2 py-1 border border-gray-300 rounded-md"
              handleChange={(event) => setHeightFOV(event.target.value)}
              value={heightFOV}
            />
          </div>
        )}

        {/* Button Change Speed */}
        {canShowSnakePathButton(editMissionId, missionId, waypoints) && (
          <div className="mt-2 w-full">
            <p className="text-grey mt-4 mb-1">Speed</p>
            <Input
              type="number"
              placeholder="Speed"
              inputClasses="w-full px-2 py-1 border border-gray-300 rounded-md"
              handleChange={(event) => setSpeed(event.target.value)}
              value={speed}
            />
          </div>
        )}

        {/* Button Change Overlap Side */}
        {canShowSnakePathButton(editMissionId, missionId, waypoints) && (
          <div className="mt-2 w-full">
            <p className="text-grey mt-4 mb-1">Overlap side</p>
            <Input
              type="number"
              placeholder="Overlap Side"
              inputClasses="w-full px-2 py-1 border border-gray-300 rounded-md"
              handleChange={(event) => setSideOverlap(event.target.value)}
              value={sideOverlap}
            />
          </div>
        )}
        {/* Button Change Frontal Side */}
        {canShowSnakePathButton(editMissionId, missionId, waypoints) && (
          <div className="mt-2 w-full">
            <p className="text-grey mt-4 mb-1">Frontal side</p>
            <Input
              type="number"
              placeholder="Overlap Front"
              inputClasses="w-full px-2 py-1 border border-gray-300 rounded-md"
              handleChange={(event) => setFrontalOverlap(event.target.value)}
              value={frontalOverlap}
            />
          </div>
        )}

        {/* Button Snake Path */}
        {canShowSnakePathButton(editMissionId, missionId, waypoints) && (
          <button
            type="button"
            className="mt-2 bg-blue w-full rounded text-white mb-3"
            onClick={() => handleCreateSnakePath()}
          >
            <FontAwesomeIcon icon={solid('snake')} />
            &nbsp; Create snake path
          </button>
        )}

        {selectedMissionId === missionId && editMissionId !== missionId && (
          <div className="text-sm">
            <div className="mt-3 mb-5">
              {mission.missionApps &&
                mission.missionApps.map((missionApp) => (
                  <div key={missionApp.missionAppId}>
                    <FontAwesomeIcon icon={solid('tablet-screen-button')} className="mr-5" />
                    {missionApp.app.name}
                  </div>
                ))}
            </div>
          </div>
        )}

        {selectedMissionId === missionId && (
          <div className="text-sm">
            <div className="select-none">
              {waypoints
                .filter((wp) => wp.waypointTypeDefinitionId === 0)
                .map((waypoint) =>
                  !checkSameCoordinatesValidator(waypoint, waypoints[0]) ? (
                    <div role="none" className="flex my-2 cursor-pointer" key={waypoint.order}>
                      {waypoint.order === 1 ? (
                        <FontAwesomeIcon
                          icon={solid('home')}
                          className={`text-blue mr-5 w-3 height-auto relative ${
                            selectedWaypoint?.order !== waypoint.order ? 'hidden' : ''
                          }`}
                        />
                      ) : waypoint.sameLocationAs(window._.find(waypoints, { order: 1 })) ? (
                        <FontAwesomeIcon
                          icon={solid('flag-checkered')}
                          className={`text-blue mr-5 w-3 height-auto relative ${
                            selectedWaypoint?.order !== waypoint.order ? 'hidden' : ''
                          }`}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={solid('circle')}
                          className={`text-blue mr-5 w-3 height-auto relative ${
                            selectedWaypoint?.order !== waypoint.order ? 'hidden' : ''
                          }`}
                        />
                      )}
                      <div
                        aria-hidden="true"
                        className={selectedWaypoint?.order === waypoint.order ? 'text-blue font-semibold' : null}
                        onClick={() => {
                          if (selectedWaypoint?.order !== waypoint.order) {
                            setSelectedWaypoint(waypoint);
                          } else {
                            setSelectedWaypoint(null);
                          }
                        }}
                      >
                        {waypoint.name !== null ? `${waypoint.name}` : `Waypoint ${waypoint.order}`}
                      </div>

                      {editMissionId === missionId && (
                        <FontAwesomeIcon
                          icon={solid('trash')}
                          style={{ marginLeft: 'auto' }}
                          className="text-red select-none"
                          onClick={() => {
                            handleRemoveWaypoint(waypoint);
                          }}
                        />
                      )}
                    </div>
                  ) : null
                )}
            </div>

            {editMissionId === missionId && (
              <div>
                {/* Check if the waypoint isn't on home already */}
                {waypoints.length > 1 &&
                  !window._.find(waypoints, { order: 1 })?.sameLocationAs(
                    window._.find(waypoints, { order: waypoints.length })
                  ) && (
                    <button
                      type="button"
                      className="bg-blue w-full rounded text-white mb-1 mt-3"
                      onClick={() => {
                        handleGoBackHome();
                      }}
                    >
                      <FontAwesomeIcon icon={solid('plus')} />
                      &nbsp; Add home waypoint
                    </button>
                  )}

                <button
                  type="button"
                  className="bg-green mb-1 rounded w-full text-white"
                  onClick={() => {
                    if (mission.missionId === undefined) {
                      createMission();
                    } else {
                      updateMission();
                    }
                  }}
                >
                  Save
                </button>

                <button
                  type="button"
                  className="bg-red rounded w-full text-white"
                  onClick={() => {
                    if (window.confirm('Are you sure you want to remove the mission?')) {
                      deleteMission();
                    }
                  }}
                >
                  Delete
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
