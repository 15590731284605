import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

export default function ErrorList({ errors }) {
  if (errors.length === 0) {
    return (
      <div className="relative flex flex-col mb-10 mt-10 bg-white shadow-md max-w-[700px] mx-auto rounded-xl bg-clip-border">
        <nav className="flex max-w-[700px] max-h-[550px] flex-col gap-1 p-2 font-sans text-base font-normal text-blue-gray-700 overflow-y-auto">
          <div className="flex items-center mt-2 mb-2 space-y-4">
            <div className="w-full bg-[#D0FFBC] rounded">
              <h4 className="text-green mt-2 mb-1 ml-2 mr-2">Weather checks were Successful!</h4>
            </div>
          </div>
        </nav>
      </div>
    );
  }

  return (
    <div className="relative flex flex-col mb-10 mt-10 bg-white shadow-md max-w-[700px] mx-auto rounded-xl bg-clip-border">
      <nav className="flex max-w-[700px] max-h-[550px] flex-col gap-1 p-2 font-sans text-base font-normal text-blue-gray-700 overflow-y-auto">
        {errors.map((error) => (
          <div key={error} className="flex items-center mt-2 mb-2 space-y-4">
            <div className="w-full bg-[#fff1f2] rounded">
              <h4 className="text-red mt-2 mb-1 ml-2 mr-2">
                <FontAwesomeIcon icon={faTimesCircle} className="mr-1" />
                {error}
              </h4>
              <p className="mb-2 ml-5 mr-2">{error.errorDescription}</p>
            </div>
          </div>
        ))}
      </nav>
    </div>
  );
}
