import React, { useState, useEffect } from 'react';
import GenericService from '../../services/generic.service';

export default function DroneDropdown({ onSelect }) {
  const [droneList, setDroneList] = useState([]);
  const [selectedDroneId, setSelectedDroneId] = useState('');

  const getDrones = () => {
    GenericService.DroneService.getDrones().then((response) => {
      setDroneList(response);
    });
  };

  const handleDroneSelection = (e) => {
    const selectedId = e.target.value;

    setSelectedDroneId(selectedId);
    onSelect(selectedId);
  };

  useEffect(() => {
    getDrones();
  }, []);

  return (
    <select
      value={selectedDroneId}
      onChange={handleDroneSelection}
      className="border-2 border-grey px-2 focus:outline-none"
    >
      <option value="" disabled hidden>
        Select a Drone To Test
      </option>
      {droneList.map((drone) => (
        <option key={drone.droneId} value={drone.droneId}>
          {drone.name} : {drone.droneId}
        </option>
      ))}
    </select>
  );
}
