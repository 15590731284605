import api from '../api';

const updateMissionApp = (missionApp) =>
  api
    .put(`/mission-apps/${missionApp.missionAppId}`, missionApp)
    .then((res) => res)
    .catch((err) => Promise.reject(err.response.data));

const createMissionApp = (missionApp) =>
  api
    .post(`/mission-apps/`, missionApp)
    .then((res) => res)
    .catch((err) => Promise.reject(err.response.data));

const deleteMissionApp = (missionAppId) =>
  api
    .delete(`/mission-apps/${missionAppId}`)
    .then((res) => res)
    .catch((err) => Promise.reject(err.response.data));

const startProcessing = (missionAppId) =>
  api
    .post(`/mission-apps/start/${missionAppId}`)
    .then((res) => res)
    .catch((err) => Promise.reject(err.response.data));

const processingStatus = (missionAppId) =>
  api
    .get(`/flightreport-apps/status/${missionAppId}`)
    .then((res) => res)
    .catch((err) => Promise.reject(err.response.data));

const MissionAppService = {
  updateMissionApp,
  createMissionApp,
  deleteMissionApp,
  startProcessing,
  processingStatus,
};

export default MissionAppService;
