import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import React from 'react';
import { floor } from 'lodash';
import useSignalRConnection from '../../../hooks/useSignalRConnection';

export default function DroneCard({ drone }) {
  const { telemetryData } = useSignalRConnection(drone.droneId);

  const batteryIcon = () => {
    if (telemetryData) {
      const battery = telemetryData.battery[0].remaining_percent;

      if (battery === 0) {
        return solid('battery-empty');
      }

      if (battery <= 5) {
        return solid('battery-low');
      }

      if (battery < 40) {
        return solid('battery-quarter');
      }

      if (battery < 60) {
        return solid('battery-half');
      }

      if (battery < 90) {
        return solid('battery-three-quarters');
      }

      if (battery >= 90) {
        return solid('battery-full');
      }

      return solid('battery-empty');
    }

    return solid('battery-empty');
  };

  return (
    <div className="w-full">
      <div
        className={`w-full p-4 bg-white shadow-lg rounded-lg border-l-4 ${
          telemetryData !== null ? 'border-online' : 'border-offline'
        }`}
      >
        <div className="grid grid-cols-2 relative">
          <div className="col-span-2">
            <div className="grid grid-flow-col">
              <h2 className="text-xl">{drone.name}</h2>
            </div>
            <div className="absolute top-0 right-0 flex items-center StatusIndicator">
              <div className={`indicator ${telemetryData !== null ? 'online' : 'offline'}`} />
              <p className="ml-2">{telemetryData !== null ? 'online' : 'offline'}</p>
            </div>
          </div>
          <div>
            <p className="mt-2 text-gray-600">Inspection round 1</p>
          </div>
        </div>

        {telemetryData ? (
          <div className="pt-4 flex items-center justify-between">
            <div>
              <FontAwesomeIcon className="w-full" icon={solid('arrows-up-down')} />
              <p className="w-full flex-nowrap">{floor(telemetryData.gps[0].relative_altitude_m)} m</p>
            </div>

            <div>
              <FontAwesomeIcon className="w-full" icon={batteryIcon()} />
              <p className="w-full">{telemetryData.battery[0].remaining_percent} %</p>
            </div>

            <div>
              <FontAwesomeIcon className="w-full" icon={solid('clock-rotate-left')} />
              <p className="w-full">Time min</p>
            </div>

            <div>
              <FontAwesomeIcon className="w-full" icon={solid('timer')} />
              <p className="w-full">Speed km/h</p>
            </div>
          </div>
        ) : (
          <div className="mt-5">Drone is not connected!</div>
        )}
      </div>
    </div>
  );
}
