import React from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';
import Button from '../Button/Button';
import ErrorList from '../ErrorList/ErrorList';
import GenericService from '../../services/generic.service';
import { FlightReport } from '../../classes/FlightReport';

export default function DroneAutomaticCheck({ checkName, checkDescription, isLoading, isSuccessful, errors }) {
  const navigate = useNavigate();
  const droneId = localStorage.getItem('droneId');
  const missionId = localStorage.getItem('missionId');

  const onCancelClick = () => {
    GenericService.FlightReportService.sendDroneCommand(droneId, missionId, 'cancel_take_off');
    navigate('/schedule');
  };

  const onStartClick = () => {
    // Create flight report
    const newFlightReport = new FlightReport();
    newFlightReport.droneId = droneId;
    newFlightReport.missionId = missionId;
    newFlightReport.executeImmediate = true;
    newFlightReport.executeAt = new Date().toISOString();

    GenericService.FlightReportService.saveFlightReport(newFlightReport);
    // Launch drone
    GenericService.FlightReportService.sendDroneCommand(droneId, missionId, 'take_off');
    // Navigate to the dashboard with the assigned drone
    navigate(`/?droneId=${droneId}`);
  };

  if (isLoading) {
    return (
      <div className="container max-w-3xl">
        <Loader />
        <h1 className="text-3xl font-bold mb-5 mt-10">{checkName}</h1>
        <p className="text-lg mb-10 color-grey">{checkDescription}</p>
        <Button text="CANCEL" buttonType="cancel" />
      </div>
    );
  }
  return (
    <div className="flex ml-5">
      <div className="w-3/4 p-4 flex flex-col justify-center">
        <div>
          {isSuccessful ? (
            <div className="container max-w-3xl">
              <h3 className="font-bold text-3xl mb-5">Check Finished! Ready to take off?</h3>
              <p className="text-lg mb-10 color-grey">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repellat explicabo, nesciunt est mollitia
                maxime veniam iure nostrum praesentium libero corrupti necessitatibus, corporis labore perferendis quam
                amet! Numquam, id excepturi. Excepturi?
              </p>
              <div className="flex gap-4">
                <Button text="CANCEL" buttonType="cancel" onClick={onCancelClick} />
                <Button text="GO" buttonType="start" onClick={onStartClick} />
              </div>
              <p className="mt-10 text-red text-lg">
                <span className="text-lg text-red font-bold">Attention: </span>Lorem, ipsum dolor sit amet consectetur
                adipisicing elit....
              </p>
            </div>
          ) : (
            <ErrorList errors={errors} />
          )}
        </div>
      </div>
    </div>
  );
}
