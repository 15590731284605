import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as turf from '@turf/turf';
import { Source, Layer, Marker } from 'react-map-gl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import useSignalRConnection from '../../../hooks/useSignalRConnection';

export default function DroneSource({ drone, selectedDrone, onDroneFocusing }) {
  // Drones data
  const [waypoints, setWaypoints] = useState([]);
  const [realTimeData, setRealTimeData] = useState(null);
  const [realTimeRoutes, setRealTimeRoutes] = useState([]);

  // Map data
  // const [shortestPathsTurf, setShortestPathsTurf] = useState(null);
  const [shortestPathsTurf, setShortestPathsTurf] = useState(null);
  const [livePositionTurf, setLivePositionTurf] = useState(null);
  const [bearingTurf, setBearingTurf] = useState(0);
  const [traversedPathTurf, setTraversedPathTurf] = useState(null);
  const [droneState, setDroneState] = useState('init');

  const { telemetryData } = useSignalRConnection(drone.droneId);

  useEffect(() => {
    if (telemetryData) {
      const longitude = telemetryData.gps[0].longtitude;
      const latitude = telemetryData.gps[0].latitiude;
      const newDroneState = telemetryData.status[0].program_state;
      setDroneState(newDroneState);

      let newWaypoints = telemetryData.mission[0].mission_list;

      if (newWaypoints) {
        newWaypoints = newWaypoints.map((wp, index) => ({
          longitude: wp.Longitude,
          latitude: wp.Latitude,
          id: `${wp.Longitude}/${wp.Latitude}/${index}`,
        }));

        if (newDroneState === 'is_flying') {
          setWaypoints(newWaypoints);
        } else {
          setWaypoints([]);
        }
      }

      // Always update the real-time data or live position of the drone when it is connected
      setRealTimeData((currData) => ({ ...currData, longitude, latitude }));

      // Only update the real-time routes when the drone is flying
      if (droneState === 'flight') {
        setRealTimeRoutes((currRoutes) => [...currRoutes, [longitude, latitude]]);
      }
    }
  }, [telemetryData]);

  useEffect(() => {
    if (droneState === 'takeoff') {
      toast.dismiss();
      toast.success(`Drone ${drone.droneId} has taken off!`);
    } else if (droneState === 'land') {
      toast.dismiss();
      toast.error(`Drone ${drone.droneId} has stopped flying!`);
    }
  }, [droneState]);

  useEffect(() => {
    if (realTimeData) {
      const { longitude, latitude } = realTimeData;
      if (realTimeData !== null) {
        const point = turf.point([longitude, latitude]);
        setLivePositionTurf(point);
      }
    }
  }, [realTimeData]);

  useEffect(() => {
    if (realTimeRoutes) {
      const numOfRoutes = realTimeRoutes.length;

      if (numOfRoutes > 1) {
        // Updating bearing data
        const start = turf.point(realTimeRoutes[numOfRoutes - 2]);
        const end = turf.point(realTimeRoutes[numOfRoutes - 1]);
        const bearing = turf.bearing(start, end);
        setBearingTurf(bearing);

        // Updating real-time traversed path
        const lineString = turf.lineString(realTimeRoutes);
        setTraversedPathTurf(lineString);
      }
    }
  }, [realTimeRoutes]);

  useEffect(() => {
    if (selectedDrone !== null && selectedDrone.droneId === drone.droneId) {
      if (realTimeData !== null) {
        onDroneFocusing(realTimeData.longitude, realTimeData.latitude, true);
      }
    }
  }, [selectedDrone]);

  useEffect(() => {
    if (waypoints.length >= 2) {
      const coordinates = waypoints.map((wp) => [wp.longitude, wp.latitude]);

      const lineString = turf.lineString(coordinates);
      setShortestPathsTurf(lineString);
    } else {
      setShortestPathsTurf(null);
    }
  }, [waypoints]);

  return (
    <>
      {/* Markers for waypoints */}
      {waypoints?.length > 0 &&
        waypoints.map((wp, index) => (
          <Marker
            id={index}
            key={wp.id}
            longitude={wp.longitude}
            latitude={wp.latitude}
            anchor="bottom"
            // onClick={(e) => markerClickedHandler(wp, e)}
            // onDrag={(e) => markerDraggedHandler(wp.id, e)}
          >
            <FontAwesomeIcon className="fa-2x map-waypoint" icon={solid('map-marker-alt')} />
          </Marker>
        ))}

      {/* Lines showing shortest paths between waypoints */}
      <Source id="shortest-paths" type="geojson" data={shortestPathsTurf}>
        {/* Simply setting lineStrings to null won't make the layer disapear! */}
        {waypoints.length >= 2 && (
          <Layer
            id="shortest-paths"
            type="line"
            paint={{
              'line-color': '#acacac',
              'line-width': 3,
            }}
          />
        )}
      </Source>
      {/* Live real-time position of the drone when it is flying */}
      <Source id={`Drone ${drone.droneId}`} type="geojson" data={livePositionTurf}>
        <Layer
          id={`drone-position-${drone.droneId}`}
          type="symbol"
          layout={{
            // This icon is a part of the Mapbox Streets style.
            // To view all images available in a Mapbox style, open
            // the style in Mapbox Studio and click the "Images" tab.
            // To add a new image to the style at runtime see
            // https://docs.mapbox.com/mapbox-gl-js/example/add-image/
            'icon-image': 'airport-15',
            'icon-rotate': bearingTurf,
            'icon-rotation-alignment': 'map',
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
            'icon-size': 1.2,
          }}
        />
      </Source>

      {/* Real-time traversed path of drone when it is flying */}
      <Source id={`traversed-paths-${drone.droneId}`} type="geojson" data={traversedPathTurf}>
        {/* Simply setting lineStrings to null won't make the layer disapear! */}
        {realTimeRoutes?.length >= 2 && (
          <Layer
            id={`traversed-paths-${drone.droneId}`}
            type="line"
            paint={{
              'line-color': '#3498db',
              'line-width': 3,
            }}
          />
        )}
      </Source>
    </>
  );
}
