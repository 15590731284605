import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

// Imports can be found here: https://mui.com/material-ui/react-stepper/

/**
 * This component renders the Horizontal Stepper element
 * which displays the checks done/failed by the drone before starting
 * the mission.
 *
 */

export default function HorizontalStepper({ steps, widthPercentage, stepsTitle }) {
  const [activeStep, setActiveStep] = useState(0);

  // This effect updates the active step if the steps change and the current step is no longer pending
  useEffect(() => {
    if (!steps[activeStep][2]) {
      // If the current step is not pending
      const nextStep = steps.findIndex((step, index) => index > activeStep && step[2]);
      if (nextStep !== -1) {
        setActiveStep(nextStep); // Set to the next pending step
      } else {
        setActiveStep(steps.length - 1); // Or set to the last step if no more pending steps
      }
    }
  }, [steps, activeStep]);

  return (
    <Box sx={{ width: `${widthPercentage}%` }}>
      <h4 className="text-grey mb-3 ml-3">{stepsTitle}</h4>
      <Stepper activeStep={activeStep}>
        {steps.map((step, index) => {
          const labelProps = {};
          if (!step[1] && step[1] != null) {
            // If the step has failed
            labelProps.error = true;
          }

          return (
            <Step key={step[0]} completed={index < activeStep || (index === activeStep && !steps[activeStep][2])}>
              <StepLabel error={labelProps.error}>{step[0]}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}
