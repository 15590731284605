import React, { useContext } from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import MapStyleSwitch from '../../../components/MapStyleSwitch/MapStyleSwitch';
import { DashboardContext } from '../Dashboard';
import DroneBar from './DroneBar/DroneBar';
import DataBar from './DataBar/DataBar';

export default function MapOverlay({ drones }) {
  const { mapStyle, setMapStyle } = useContext(DashboardContext);
  const { selectedDrone, setSelectedDrone } = useContext(DashboardContext);

  const headingClickedHandler = () => {
    if (selectedDrone !== null) {
      setSelectedDrone(null);
    }
  };

  return (
    <>
      <div className="map-overlay-left">
        <div>
          <span
            className="heading cursor-pointer"
            onClick={headingClickedHandler}
            onKeyDown={headingClickedHandler}
            role="presentation"
          >
            {selectedDrone !== null && <FontAwesomeIcon icon={regular('chevron-left')} />}

            <h2 style={{ color: mapStyle === 'streets-v11' ? 'black' : 'white' }} className="ml-3">
              {selectedDrone !== null ? selectedDrone.name : 'Dashboard'}
            </h2>
          </span>

          {selectedDrone !== null && <DataBar selectedDrone={selectedDrone} />}
        </div>
      </div>

      {selectedDrone === null && (
        <div className="fixed bottom-0">
          <DroneBar drones={drones} />
        </div>
      )}

      <MapStyleSwitch setMapStyle={setMapStyle} />
    </>
  );
}
