import React from 'react';
import './Input.scss';

export default function Input({
  label,
  handleChange,
  onBlur,
  value,
  placeholder,
  inputType,
  handleRef,
  onKeyDown,
  style,
  inputClasses,
}) {
  return (
    <div>
      {label && <p className="text-grey mt-4 mb-1">{label}</p>}
      <input
        className={inputClasses}
        onChange={handleChange}
        ref={handleRef}
        onBlur={onBlur}
        value={value}
        type={inputType}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        style={style}
      />
    </div>
  );
}
