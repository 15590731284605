import React from 'react';
import './App.scss';
import { Routes, Route, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import SideNav from './SideNav/SideNav';
import Dashboard from '../../pages/Dashboard/Dashboard';
import TestPage from '../../pages/TestPage/TestPage';
import Login from '../../pages/Login/Login';
import Missions from '../../pages/Missions/Missions';
import CustomRouter from '../../custom/CustomRouter';
import CustomHistory from '../../custom/CustomHistory';
import ManualInspection from '../../pages/FlightReport/ManualInspection';
import Users from '../../pages/Users/Users';
import UserForm from '../../pages/Users/UserForm';
import DroneForm from '../../pages/Users/DroneForm';
import TokenService from '../../services/Authentication/token.service';
import FlightReportList from '../../pages/FlightReport/FlightReportList';
import AppStore from '../../pages/AppStore/AppStore';
import AppStoreDetails from '../../pages/AppStore/AppDetails/AppDetails';
import AutomaticChecks from '../../pages/AutomaticChecks/AutomaticChecks';
import ScheduleMission from '../../pages/ScheduleMission/ScheduleMission';

export default function App() {
  const token = TokenService.getLocalAccessToken();
  const role = token ? TokenService.getRoleFromToken() : null;

  return (
    <div className="app">
      <CustomRouter history={CustomHistory}>
        <AppContent role={role} />
      </CustomRouter>
      <ToastContainer />
    </div>
  );
}

function AppContent({ role }) {
  const location = useLocation();

  return (
    <>
      {location.pathname !== '/login' && location.pathname !== '/automaticchecks' && <SideNav />}
      <Routes>
        <Route exact path="/" element={<Dashboard />} />
        <Route path="/schedule" element={<ScheduleMission />} />
        <Route path="/automaticchecks" element={<AutomaticChecks isAdmin={role === 'Admin'} />} />
        <Route path="/manualinspection" element={<ManualInspection />} />
        <Route path="/missions" element={<Missions />} />
        <Route path="/login" element={<Login />} />
        <Route path="/data" element={<FlightReportList />} />
        <Route path="/appstore" element={<AppStore />} />
        <Route path="/appstore/:appId" element={<AppStoreDetails />} />

        {/* Only add routes if the user has the Admin role */}
        {role === 'Admin' ? (
          <>
            <Route path="/testpage" element={<TestPage />} />
            <Route path="/users" element={<Users />} />
            <Route exact path="/users/:id" element={<UserForm />} />
            <Route exact path="/drones/:id" element={<DroneForm />} />
          </>
        ) : null}
      </Routes>
    </>
  );
}
