import React, { useContext } from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popup } from 'react-map-gl';
import { MissionsContext } from '../Missions';
import Input from '../../../components/Input/Input';
import { Waypoint } from '../../../classes/Waypoint';

export default function WaypointPopup() {
  const {
    selectedWaypoint,
    setSelectedWaypoint,
    editMissionId,
    setEditMissionId,
    selectedMissionId,
    setWaypoints,
    setCurrentAltitude,
    setShowAltitudeInput,
  } = useContext(MissionsContext);

  const handleEditIconClick = () => {
    const isEditIconSelected = editMissionId === selectedMissionId;
    const newEditMissionId = isEditIconSelected ? 0 : selectedMissionId;
    setEditMissionId(newEditMissionId);
    setShowAltitudeInput(!isEditIconSelected);
  };

  const updateWaypointProperty = (property, value) => {
    setWaypoints((oldWps) =>
      oldWps.map((wp) => {
        if (wp.order === selectedWaypoint.order) {
          const waypoint = new Waypoint({
            ...wp,
            [property]: value ? Number(value) : 0,
          });

          setSelectedWaypoint(waypoint);
          return waypoint;
        }

        return wp;
      })
    );
  };

  const updateWaypointName = (e) => {
    const newName = e.target.textContent.trim();
    const defaultPlaceholder = `Waypoint ${selectedWaypoint.order}`;

    if (newName !== defaultPlaceholder && newName !== '') {
      setWaypoints((oldWps) =>
        oldWps.map((wp) => {
          if (wp.order === selectedWaypoint.order) {
            const updatedWaypoint = new Waypoint({
              ...wp,
              name: newName,
            });

            setSelectedWaypoint(updatedWaypoint);
            return updatedWaypoint;
          }

          return wp;
        })
      );
    }
  };

  return (
    <Popup
      anchor="top"
      longitude={selectedWaypoint.longitude}
      latitude={selectedWaypoint.latitude}
      onClose={() => {
        setSelectedWaypoint(null);
      }}
      closeButton={false}
      style={{ width: '400px' }}
    >
      <div>
        <div className="color-grey">
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <p
            className="popup-title"
            contentEditable={editMissionId === selectedMissionId}
            onBlur={updateWaypointName}
            suppressContentEditableWarning
            onKeyDown={(e) => {
              // Handle Enter key press to prevent adding new lines in contentEditable
              if (e.key === 'Enter') {
                e.preventDefault();
                e.target.blur();
              }
            }}
          >
            {selectedWaypoint.name !== null ? selectedWaypoint.name : `Waypoint ${selectedWaypoint.order}`}
          </p>
          <FontAwesomeIcon
            icon={editMissionId === selectedMissionId ? solid('pen-slash') : solid('pen')}
            className="float-right unselectable edit-icon"
            onClick={handleEditIconClick}
          />
        </div>
        <br />
        <hr className="color-grey" />
        <div>
          <div>
            <p className="coordinate-title">Longitude</p>
            {editMissionId === selectedMissionId ? (
              <Input
                inputType="number"
                value={selectedWaypoint.longitude}
                handleChange={(e) => updateWaypointProperty('longitude', e.target.value)}
                className="waypoint-info-input"
              />
            ) : (
              <div className="coordinate-item">{selectedWaypoint.longitude}</div>
            )}
          </div>
          <br />
          <div>
            <p className="coordinate-title">Latitude</p>
            {editMissionId === selectedMissionId ? (
              <Input
                inputType="number"
                value={selectedWaypoint.latitude}
                handleChange={(e) => updateWaypointProperty('latitude', e.target.value)}
                className="waypoint-info-input"
              />
            ) : (
              <div className="coordinate-item">{selectedWaypoint.latitude}</div>
            )}
          </div>
          <br />
          <div>
            <p className="coordinate-title">Altitude</p>
            {editMissionId === selectedMissionId ? (
              <Input
                inputType="number"
                value={selectedWaypoint.altitude === 0 ? '' : selectedWaypoint.altitude}
                handleChange={(e) => {
                  updateWaypointProperty('altitude', e.target.value);
                  setCurrentAltitude(Number(e.target.value));
                }}
                className="waypoint-info-input"
              />
            ) : (
              <div className="coordinate-item">{selectedWaypoint.altitude}</div>
            )}
          </div>
          <br />
          <div>
            <p className="coordinate-title">Heading</p>
            {editMissionId === selectedMissionId ? (
              <Input
                inputType="number"
                value={selectedWaypoint.heading === 0 ? '' : selectedWaypoint.heading}
                handleChange={(e) => updateWaypointProperty('heading', e.target.value)}
                className="waypoint-info-input"
              />
            ) : (
              <div className="coordinate-item">{selectedWaypoint.heading}</div>
            )}
          </div>
        </div>
      </div>
    </Popup>
  );
}
