import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../shared.scss';
import StepperCheckManual from '../../components/StepperPhysicalPreperation/StepperCheckManual';
import { Stepper } from '../../components/Steppers/Stepper';
import BatteryLevelText from '../../components/StepperPhysicalPreperation/battery-level-text.json';
import LooseComponentText from '../../components/StepperPhysicalPreperation/loose-component-text.json';
import PropellorDamageText from '../../components/StepperPhysicalPreperation/propellor-damage-text.json';
import RemoteControlerText from '../../components/StepperPhysicalPreperation/remote-controller-check.json';

export default function ManualInspection() {
  const navigate = useNavigate();
  const [checkStep, setCheckStep] = useState(1);

  const nextCheck = () => {
    setCheckStep((cur) => cur + 1);
  };

  const prevCheck = () => {
    if (checkStep === 1) {
      navigate('/schedule');
    }
    setCheckStep((cur) => cur - 1);
  };

  return (
    <div className="flex flex-col md:flex-row">
      {checkStep === 1 && (
        <StepperCheckManual
          stepTitle={PropellorDamageText.stepTitile}
          stepDescription={PropellorDamageText.stepDescription}
        />
      )}
      {checkStep === 2 && (
        <StepperCheckManual
          stepTitle={LooseComponentText.stepTitile}
          stepDescription={LooseComponentText.stepDescription}
        />
      )}
      {checkStep === 3 && (
        <StepperCheckManual
          stepTitle={BatteryLevelText.stepTitile}
          stepDescription={BatteryLevelText.stepDescription}
        />
      )}
      {checkStep === 4 && (
        <StepperCheckManual
          stepTitle={RemoteControlerText.stepTitile}
          stepDescription={RemoteControlerText.stepDescription}
        />
      )}
      {checkStep !== 0 && <Stepper nextCheckHandler={nextCheck} previousCheckHandler={prevCheck} />}
    </div>
  );
}
