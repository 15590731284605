import api from '../api';

const getWaypoints = () =>
  api
    .get('/waypoints')
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response.data));

const updateWaypoint = (waypoint) =>
  api
    .put(`/waypoints/${waypoint.waypointId}`, waypoint)
    .then((res) => res)
    .catch((err) => Promise.reject(err.response.data));

const deleteWaypoint = (waypointId) =>
  api
    .delete(`/waypoints/${waypointId}`)
    .then((res) => res)
    .catch((err) => Promise.reject(err.response.data));

const WaypointService = {
  getWaypoints,
  updateWaypoint,
  deleteWaypoint,
};

export default WaypointService;
